import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";

import { withStyles, Grid } from "@material-ui/core";

import {
  Domain,
  Group,
  Info,
  Cloud,
  Alarm,
  PhoneIphone,
} from "@material-ui/icons";

import {
  StatsCard,
  ItemGrid,
} from "../../components";

import {
  // getWaylayResourcesList,
  // getUsers,
} from "../../actions/admin";


import dashboardStyle from "../../assets/jss/material-dashboard-react/dashboardStyle";

class Dashboard extends Component {
  render() {
    return (
      this.props.appIsBusy
      ? <div />
      : (
      <div>
        <Grid container>
          <ItemGrid xs={12} sm={6} md={3}>
            <Link to='/customers'>
              <StatsCard
                icon={Domain}
                iconColor="blue"
                title={<Translate id="dashboard.Customers" />}
                description={this.props.customers.length}
                statIcon={Info}
                statText={<Translate id="dashboard.RegisteredCustomers" />}
              />
            </Link>
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <Link to='/users'>
              <StatsCard
                icon={Group}
                iconColor="orange"
                title={<Translate id="dashboard.Users" />}
                description={this.props.users.length}
                statIcon={Info}
                statText={<Translate id="dashboard.RegisteredUsers" />}
              />
              </Link>
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <Link to='/resourcetypes'>
              <StatsCard
                icon={PhoneIphone}
                iconColor="purple"
                title={<Translate id="dashboard.Resource_Types" />}
                description={this.props.resourceType.length}
                statIcon={Info}
                statText={<Translate id="dashboard.Resource_Types" />}
              />
              </Link>
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <Link to='/resources'>
              <StatsCard
                icon={Cloud}
                iconColor="green"
                title={<Translate id="dashboard.Resources" />}
                description={this.props.resources.length}
                statIcon={Info}
                statText={<Translate id="dashboard.Resources" />}
              />
            </Link>
          </ItemGrid>
          <ItemGrid xs={12} sm={6} md={3}>
            <Link to='/alarms'>
              <StatsCard
                icon={Alarm}
                iconColor="red"
                title={<Translate id="dashboard.Alarms" />}
                description={this.props.alarms.length}
                statIcon={Info}
                statText={<Translate id="dashboard.Alarms" />}
              />
            </Link>
          </ItemGrid>
        </Grid>
      </div>
    ));
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  resources: state.user.resources,
  alarms: state.user.alarms,
  users: state.admin.users,
  customers: state.admin.customers,
  resourceType: state.admin.resourceTypes,
  appIsBusy: state.appState.appIsBusy,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  // getWaylayResourcesList,
  // getUsers
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(Dashboard));
