import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import { withStyles, Grid } from "@material-ui/core";

import {
  Info,
  Cloud,
  // Alarm,
} from "@material-ui/icons";

import {
  StatsCard,
  ItemGrid,
} from "../../components";

import dashboardStyle from "../../assets/jss/material-dashboard-react/dashboardStyle";

class Dashboard extends Component {
  render() {
    return (
      this.props.appIsBusy
      ? <div />
      : (
      <div>
        <Grid container>
          <ItemGrid xs={12} sm={6} md={3}>
            <Link to='/resources'>
              <StatsCard
                icon={Cloud}
                iconColor="green"
                title={<Translate id="dashboard.Resources" />}
                description={this.props.resources.length}
                statIcon={Info}
                statText={<Translate id="dashboard.Resources" />}
              />
            </Link>
          </ItemGrid>
{/*          <ItemGrid xs={12} sm={6} md={3}>
            <Link to='/alarms'>
              <StatsCard
                icon={Alarm}
                iconColor="red"
                title={<Translate id="dashboard.Alarms" />}
                description={this.props.alarms.length}
                statIcon={Info}
                statText={<Translate id="dashboard.Alarms" />}
              />
            </Link>
          </ItemGrid> */}
        </Grid>
      </div>
    ));
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  resources: state.user.resources,
  // alarms: state.user.alarms,
  appIsBusy: state.appState.appIsBusy,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  // getCustomerResources,
  // updateCustomerResource,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyle)(Dashboard));
