const initialState = {
  token: null,
  sessionErrMessage: '',
  isSigningIn: false,
  role: 0,
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SIGNIN_REQUEST':
        return {
            ...state,
            sessionErrMessage: '',
            isSigningIn: true,
        };

      case 'SIGNIN_SUCCESS':
        return {
            ...state,
            token: action.res.token,
            sessionErrMessage: '',
            isSigningIn: false,
            role: action.res.role,
        };

      case 'SIGNIN_FAILURE':
        return {
            ...state,
            sessionErrMessage: action.err ? action.err.statusText : 'Unknown error',
            token: null,
            isSigningIn: false,
        };

      case 'RESTORE_AUTH_STATE':
        return {
          ...state,
          token: action.token ? action.token.token : null,
          role: action.token ? action.token.role : false,
        };

      case 'SIGNOUT_REQUEST':
        return {
            ...state,
            token: null,
        };

      default:
        return {...state};
    }
}

export default sessionReducer;
