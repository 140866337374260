import React, { Component } from "react";
import { ItemGrid } from "../";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import SnackbarContent from './SnackbarContent.jsx';

export default class Snackbars extends Component {
  render() {
    return  <ItemGrid xs={12} sm={6} md={3} style={{position: 'absolute', top: '12px', right: '24px', zIndex: 9999}}>
      <TransitionGroup>
      {this.props.snackbars.map(snack =>
        <CSSTransition
              key={snack.id}
              classNames="snackbar-hide"
              timeout={{exit: 1000}}
        >
          <SnackbarContent
            color={snack.color || 'success'}
            message={snack.text}
            open={snack.open || true}
            closeNotification={() => this.props.closeSnackbar(snack.id)}
            close
          />
        </CSSTransition>)}
      </TransitionGroup>
    </ItemGrid>
  }
}
