import { createStore, applyMiddleware, compose  } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger'

import reducer from '../reducers';
import localStorageMiddleware from './localStorageMiddleware';
import createSnackbars from './createSnackbars';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducer,
	composeEnhancers(
    	applyMiddleware(thunk, localStorageMiddleware, createSnackbars)
    )
);

export default store;
