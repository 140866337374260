import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormLabel } from "@material-ui/core";
import { withFormik, Field, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { Translate } from "react-localize-redux";

import {
  RegularCard,
  Button,
  CustomInput,
  ItemGrid
} from "../../components";

import {
  sendResetPasswordEmail
} from "../../actions/user";

import {
  Grid,
  CircularProgress,
  FormControl,
  withStyles,
} from "@material-ui/core";

const styles = theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column'
  },
  spinner: {
    alignSelf: 'center'
  }
});

const ForgotPasswordFormVS = yupObject().shape({
  loginEmail: yupString()
    .email('invalid')
    .required('required'),
});

const ForgotPassword = withFormik({
	mapPropsToValues: () => ({ loginEmail: '', success: false }),
	validationSchema: ForgotPasswordFormVS,
  isInitialValid: (props) => {
    ForgotPasswordFormVS.isValid(props.initialValues)
    .then(valid => valid)
  },
	handleSubmit: (values, { props, setFieldValue }) => {
		const data = {
			loginEmail: values.loginEmail,
		};
		props.sendResetPasswordEmail(data, () => setFieldValue('success', true));
	},
})(props => {
	const {
		errors,
		touched,
    isValid,
    classes,
    values,
	} = props;
	return (
          <Grid container justify="center">
            <ItemGrid xs={12} sm={12} md={3}>
              <RegularCard
                cardTitle={<Translate id="reset.title" />}
                headerColor='darkblue'
                content={
              		<Form className="login-form">
                    <Grid className={classes.grid}>
              			<FormControl>
                      <Field
                        name="loginEmail"
                        render={({field, form}) => (
                          <CustomInput
                             labelText={<Translate id="reset.email" />}
                             disabled={props.isSigningIn}
                             inputProps={{
                               type: "email",
                               name: field.name,
                               error: errors.loginEmail && touched.loginEmail,
                               value: props.values.loginEmail,
                               onChange: form.handleChange,
                               onBlur: form.handleBlur,
                             }}
                             formControlProps={{
                               fullWidth: true
                             }}
                          />
                        )}
                      />
              				{errors.loginEmail && touched.loginEmail &&
                        <FormLabel
                          error
                        >
                          <Translate id={`reset.errors.password.${errors.loginEmail}`} />
                        </FormLabel>}
              			</FormControl>
                    {props.isSendingEmail
                      ? <CircularProgress className={classes.spinner} />
                      : <Button
                          fullWidth
                          color="blue"
                          type="submit"
                          disabled={!isValid}
                        >
                          {<Translate id="reset.send" />}
                        </Button>}
                    <FormLabel
                      error
                    >
                      {props.sendEmailErrMessage}
                    </FormLabel>
                    <FormLabel style={{color: 'green'}}>
                      {values.success ? <small>
                        {<Translate id="reset.prompt" data={{email: values.loginEmail}}/>}</small> : ''}
                    </FormLabel>
                  </Grid>
              		</Form>
                }
              />
            </ItemGrid>
          </Grid>
        )
});

const mapStateToProps = state => ({
  sendEmailErrMessage: state.user.sendEmailErrMessage,
  isSendingEmail: state.user.isSendingEmail,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  sendResetPasswordEmail,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword));
