import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { withLocalize } from "react-localize-redux";

import Spinner  from 'react-spinkit';

import "./assets/css/material-dashboard-react.css?v=1.2.0";
import "./assets/css/animations.css";

import indexRoutes from "./routes/index.jsx";
import publicRoutes from "./routes/public.jsx";
import { restoreAuthState, tokenInvalid } from "./actions/session";
import { setAppIsBusy, setAppIsNotBusy, enableLocalization, disableLocalization } from "./actions/appState";
import httpService from './services/httpService';
import initLocalize from './services/localizeService';


const loaderStyle = {
  position: "fixed",
  left: "0",
  top: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "black",
  opacity: "0.1",
  zIndex: "1030"
};

const spinnerStyle = {
  position: "absolute",
  width: "36px",
  height: "36px",
  left: "calc(50% - 18px)",
  top: "calc(50% - 18px)",
  opacity: "1",
  zIndex: "1030"
};

const Loader = ({ appIsBusy, children }) =>
  <div>
    {appIsBusy ? <div className="loader" style={loaderStyle} /> : null}
    {appIsBusy ? <Spinner name='cube-grid' style={spinnerStyle} color="darkblue" /> : null}
    {children}
  </div>

class App extends Component {
  constructor(props) {
    super(props);

    if(new URLSearchParams(props.location.search).get('lang') === "1") {
      props.enableLocalization();
    } else {
      props.disableLocalization();
    }
    props.restoreAuthState();
    this.initHttpService();

    initLocalize(props.initialize, props.addTranslationForLanguage, props.setActiveLanguage);
  }

  initHttpService = () => {
    httpService.interceptors.request.use(config => {
        this.props.setAppIsBusy();
        if(this.props.token) {
          config.headers['x-access-token'] = this.props.token;
        } else {
          delete config.headers['x-access-token'];
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    httpService.interceptors.response.use(response => {
        this.props.setAppIsNotBusy();
        return response;
      },
      error => {
        this.props.setAppIsNotBusy();
        if(error.response
          && error.response.status === 401
          && this.props.token) {
          this.props.tokenInvalid();
        }
        return Promise.reject(error);
      }
    );
  }

  render() {
    const { appIsBusy } = this.props;
    return (
      <Loader appIsBusy={appIsBusy}>
        <Switch>
          {this.props.auth
          ? (indexRoutes.map((prop, key) =>
              <Route path={prop.path} component={prop.component} key={key} />
            )
          ):(
            publicRoutes.map((prop, key) =>
              <Route path={prop.path} component={prop.component} key={key} />
            )
          )}
        </Switch>
      </Loader>
    );
  }
}

const mapStateToProps = state => ({
  auth: Boolean(state.session.token),
  role: state.session.role,
  token: state.session.token,
  appIsBusy: state.appState.appIsBusy,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  enableLocalization,
  disableLocalization,
  restoreAuthState,
  tokenInvalid,
  setAppIsBusy,
  setAppIsNotBusy,
}, dispatch));

export default withLocalize(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
