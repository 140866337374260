import api from '../api/user';

export const getResources = () => dispatch => {
  dispatch({ type: 'GET_RESOURCES_REQUEST' });
  api.getResources()
      .then(res => {
          dispatch({ type: 'GET_RESOURCES_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_RESOURCES_FAILURE', err: err.response });
      })
}

export const updateResource = (resourceId, data, cb) => dispatch => {
  dispatch({ type: 'UPDATE_RESOURCE_REQUEST' });
  api.updateResource(resourceId, data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'UPDATE_RESOURCE_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'UPDATE_RESOURCE_FAILURE', err: err.response });
      })
}

export const updateEmailAlerts = (resourceId, data, cb) => dispatch => {
  dispatch({ type: 'UPDATE_RESOURCE_REQUEST' });
  api.updateEmailAlerts(resourceId, data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'UPDATE_RESOURCE_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'UPDATE_RESOURCE_FAILURE', err: err.response });
      })
}

export const getAlarms = () => dispatch => {
  dispatch({ type: 'GET_ALARMS_REQUEST' });
  api.getAlarms()
      .then(res => {
          dispatch({ type: 'GET_ALARMS_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_ALARMS_FAILURE', err: err.response });
      })
}

export const deleteAlarm = alarmId => dispatch => {
  dispatch({ type: 'DELETE_ALARM_REQUEST' });
  api.deleteAlarm(alarmId)
      .then(res => {
          dispatch({ type: 'DELETE_ALARM_SUCCESS', id: alarmId });
      })
      .catch(err => {
          dispatch({ type: 'DELETE_ALARM_FAILURE', err: err.response });
      })
}

export const updateAlarm = (alarmId, data) => dispatch => {
  dispatch({ type: 'UPDATE_ALARM_REQUEST' });
  api.updateAlarm(alarmId, data)
      .then(res => {
          dispatch({ type: 'UPDATE_ALARM_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'UPDATE_ALARM_FAILURE', err: err.response });
      })
};

export const getProfile = () => dispatch => {
  dispatch({ type: 'GET_PROFILE_REQUEST' });
  api.getProfile()
      .then(res => {
          dispatch({ type: 'GET_PROFILE_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_PROFILE_FAILURE', err: err.response });
      })
}

export const updateProfile = (data, cb) => dispatch => {
  dispatch({ type: 'UPDATE_PROFILE_REQUEST' });
  api.updateProfile(data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'UPDATE_PROFILE_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'UPDATE_PROFILE_FAILURE', err: err.response });
      })
}

export const sendResetPasswordEmail = (email, cb) => dispatch => {
  dispatch({ type: 'SEND_RESET_PASSWORD_EMAIL_REQUEST' });
  api.sendResetPasswordEmail(email)
      .then(res => {
          cb && cb();
          dispatch({ type: 'SEND_RESET_PASSWORD_EMAIL_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'SEND_RESET_PASSWORD_EMAIL_FAILURE', err: err.response });
      })
}

export const setNewPassword = (payload, cb) => dispatch => {
  dispatch({ type: 'SET_NEW_PASSWORD_REQUEST' });
  api.setNewPassword(payload)
      .then(res => {
          cb && cb();
          dispatch({ type: 'SET_NEW_PASSWORD_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'SET_NEW_PASSWORD_FAILURE', err: err.response });
      })
}

export const createSnackbar = data => dispatch => {
  dispatch({ type: 'CREATE_SNACKBAR_REQUEST', data });
}

export const closeSnackbar = id => dispatch => {
  dispatch({ type: 'CLOSE_SNACKBAR_REQUEST', id });
}

export const getMessages = (resourceId, cb) => dispatch => {
  dispatch({ type: 'GET_MESSAGES_REQUEST' });
  api.getMessages(resourceId)
      .then(res => {
          cb && cb(res.data);
          dispatch({ type: 'GET_MESSAGES_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_MESSAGES_FAILURE', err: err.response });
      })
};

export const sendCommand = (resourceId, commandInHex, cb) => dispatch => {
  dispatch({ type: 'SEND_COMMAND_REQUEST' });
  api.sendCommand(resourceId, commandInHex)
    .then(res => {
        cb && cb();
        dispatch({ type: 'SEND_COMMAND_SUCCESS', res: res.data });
    })
    .catch(err => {
        dispatch({ type: 'SEND_COMMAND_FAILURE', err: err.response });
    })
}
