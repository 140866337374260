import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormLabel } from "@material-ui/core";
import { withFormik, Field, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

import {
  RegularCard,
  Button,
  CustomInput,
  ItemGrid
} from "../../components";

import {
  signin
} from "../../actions/session";

import {
  Grid,
  CircularProgress,
  FormControl,
  withStyles,
} from "@material-ui/core";

const styles = theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column'
  },
  spinner: {
    alignSelf: 'center'
  }
});

const LoginFormVS = yupObject().shape({
  email: yupString()
    .email('invalid')
    .required('required'),
  password: yupString()
    .required('required'),
});


export const Login = withFormik({
	mapPropsToValues: () => ({ email: '', password: '' }),
	validationSchema: LoginFormVS,
  isInitialValid: (props) => {
    LoginFormVS.isValid(props.initialValues)
    .then(valid => valid)
  },
	handleSubmit: (values, { props }) => {
		const data = {
			loginEmail: values.email,
			password: values.password,
		};
		props.signin(data);
	},
})(props => {
	const {
		errors,
		touched,
    isValid,
    classes,
	} = props;
	return (
      <div>
          <Grid container justify="center">
            <ItemGrid xs={12} sm={12} md={3}>
              <RegularCard
                cardTitle={<Translate id="login.title" />}
                headerColor='darkblue'
                content={
              		<Form className="login-form">
                    <Grid className={classes.grid}>
              			<FormControl>
                      <Field
                        name="email"
                        render={({field, form}) => (
                          <CustomInput
                             labelText={<Translate id="login.email" />}
                             disabled={props.isSigningIn}
                             inputProps={{
                               type: "email",
                               name: "email",
                               error: errors.email && touched.email,
                               value: props.values.email,
                               onChange: form.handleChange,
                               onBlur: form.handleBlur,
                             }}
                             formControlProps={{
                               fullWidth: true
                             }}
                          />
                        )}
                      />
              				{errors.email && touched.email &&
                        <FormLabel
                          error
                        >
                          <Translate id={`login.errors.email.${errors.email}`} />
                        </FormLabel>}
              			</FormControl>
                    <FormControl>
                      <CustomInput
                         labelText={<Translate id="login.password" />}
                         disabled={props.isSigningIn}
                         inputProps={{
                           type: "password",
                           name: "password",
                           error: errors.password && touched.password,
                           value: props.values.password,
                           onChange: props.handleChange,
                           onBlur: props.handleBlur,
                         }}
                         formControlProps={{
                           fullWidth: true
                         }}
                      />
                      {errors.password && touched.password &&
                        <FormLabel
                          error
                        >
                          <Translate id={`login.errors.password.${errors.password}`} />
                        </FormLabel>}
                    </FormControl>
                    { props.isSigningIn
                      ? <CircularProgress className={classes.spinner} />
                      : <Button
                          fullWidth
                          color="blue"
                          type="submit"
                          disabled={!isValid}
                        >
                          {<Translate id="login.signin" />}
                        </Button>}
                    <FormLabel
                      error
                    >
                      {props.sessionErrMessage}
                    </FormLabel>
                  </Grid>
              		</Form>
                }
              />
            </ItemGrid>
          </Grid>
          <Grid container justify="center">
            <ItemGrid  xs={12} sm={12} md={3} style={{textAlign: 'right', fontSize: 'small'}}>
              <Link to='/forgot_password'>{<Translate id="login.forgot_password" />}</Link>
            </ItemGrid>
          </Grid>
        </div>
        )
});

const mapStateToProps = state => ({
  sessionErrMessage: state.session.sessionErrMessage,
  isSigningIn: state.session.isSigningIn,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  signin,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
