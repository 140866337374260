import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormLabel } from "@material-ui/core";
import { withFormik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { object as yupObject, string as yupString, ref as yupRef } from 'yup';
import { Translate } from "react-localize-redux";

import {
  RegularCard,
  Button,
  CustomInput,
  ItemGrid
} from "../../components";

import {
  setNewPassword
} from "../../actions/user";

import {
  Grid,
  CircularProgress,
  FormControl,
  withStyles,
} from "@material-ui/core";

const styles = theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column'
  },
  spinner: {
    alignSelf: 'center'
  }
});

const SetPasswordFormVS = yupObject().shape({
  new_password: yupString()
  		    .required('required'),
  conf_password: yupString()
    	    .oneOf([yupRef('new_password', null)],  'match')
    	    .required('required'),
  token: yupString()
        .required('not_found')
});

const SetPassword = withFormik({
	mapPropsToValues: (props) => ({
    new_password: '',
    conf_password: '',
    token: new URLSearchParams(props.location.search).get('token') || '',
    success: false
  }),
	validationSchema: SetPasswordFormVS,
  enableReinitialize: true,
  isInitialValid: (props) => {
    SetPasswordFormVS.isValid(props.initialValues)
    .then(valid => valid)
  },
	handleSubmit: (values, { props, setFieldValue }) => {
		const data = {
			new_password: values.new_password,
      token: values.token,
		};
		props.setNewPassword(data, () => setFieldValue('success', true));
	},
})(props => {
	const {
		errors,
		touched,
    isValid,
    classes,
    values,
	} = props;
	return (
    <Grid container justify="center">
      <ItemGrid xs={12} sm={12} md={3}>
        <RegularCard
          cardTitle={<Translate id="set.label" />}
          headerColor='darkblue'
          content={
            <Form className="login-form">
              <Grid className={classes.grid}>
                <FormControl fullWidth>
                  <Field
                    name="new_password"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="set.new_password" />}
                       inputProps={{
                         "type": "password",
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": props.isSettingPassword
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.new_password && touched.new_password &&
                    <FormLabel
                      error
                    >
                      <Translate id={`set.errors.password.${errors.new_password}`} />
                    </FormLabel>}
                </FormControl>
                <FormControl fullWidth>
                  <Field
                    name="conf_password"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="set.conf_password" />}
                       inputProps={{
                         "type": "password",
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": props.isSettingPassword
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.conf_password && touched.conf_password &&
                    <FormLabel
                      error
                    >
                      <Translate id={`set.errors.password.${errors.conf_password}`} />
                    </FormLabel>}
                </FormControl>

              {props.isSettingPassword
                ? <CircularProgress className={classes.spinner} />
                : <Button
                    fullWidth
                    color="blue"
                    type="submit"
                    disabled={!isValid}
                  >
                    <Translate id={"set.button"} />
                  </Button>}
              <FormLabel
                error
              >
                {props.setPasswordErrMessage}
              </FormLabel>
              <FormLabel style={{color: 'green'}}>
                {values.success ? <small><Translate id={"set.prompt"} /> <Link to='login'>Login</Link>.</small> : ''}
              </FormLabel>
            </Grid>
            </Form>
          }
        />
      </ItemGrid>
    </Grid>)
});

const mapStateToProps = state => ({
  setPasswordErrMessage: state.user.setPasswordErrMessage,
  isSettingPassword: state.user.isSettingPassword,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  setNewPassword,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetPassword));
