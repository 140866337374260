import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";
import ErrorBoundary from './errorBoundary';
import store from './store';
import "./assets/css/material-dashboard-react.css?v=1.2.0";

import App from "./app";
// import i18n from './i18n'; // initialized i18next instance

const hist = createBrowserHistory();

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  }
});

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <Router history={hist}>
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </Router>
      </LocalizeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
