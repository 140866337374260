import api from '../api/session';

export const signin = data => dispatch => {
  dispatch({ type: 'SIGNIN_REQUEST' });
  api.signin(data)
      .then(res => {
          dispatch({ type: 'SIGNIN_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'SIGNIN_FAILURE', err: err.response });
      })
}

export const signout = () => ({ type: 'SIGNOUT_REQUEST' });
export const restoreAuthState = () => ({ type: 'RESTORE_AUTH_STATE' });
export const tokenInvalid = () => ({ type: 'TOKEN_INVALID' });
