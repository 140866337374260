export default {
	SIGNIN: '/signin',
	SIGNIN_AS: 'signin_as',
	KEYS: '/keys',
	USER: '/user',
	CREATE_USER: '/createUser',
	USERS: '/users',
	CUSTOMER: '/customer',
	CUSTOMERS: '/customers',
	WAYLAY_RESOURCES: '/waylay/resources',
	RESOURCES: '/resources',
	RESOURCE: '/resource',
	EMAIL_ALERTS: '/email_alerts',
	ALARMS: '/alarms',
	ALARM: '/alarm',
	PROFILE: '/profile',
	FORGOT_PASSWORD: '/forgot_password',
	SET_PASSWORD: '/set_password',
	RESOURCE_TYPES: '/resourcetypes',
	RESOURCE_TYPE: '/resourcetype',
	MESSAGES: '/messages',
	BACKUP_DB: '/backup',
	RESTORE_DB: '/restore',
	TOKEN: '/get-token',
	SEND_COMMAND: '/send-command',
}
