import React from "react";
import { InputAdornment, IconButton, TextField, Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

const styles = theme => ({
  container: {
    marginBottom: "6px",
  },
  searchIcon: {
    display: "flex",
  },
  textField: {
    width: "100%"
  },
  label: {
    fontSize: "small"
  }
});

const FilterInput = ({ ...props }) => {
  const {
    value,
    handleChange,
    field,
    classes,
  } = props;

  return (
    <Grid container spacing={8} className={classes.container} alignItems="flex-end">
      <TextField
        id={`adornment-${field}`}
        className={classes.textField}
        value={value}
        label={`search ${field}...`}
        onChange={e => handleChange(field, e.target.value)}
        InputLabelProps={{className: classes.label}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear"
                onClick={e => handleChange(field, '')}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Grid>
  );
}

export default withStyles(styles)(FilterInput);
