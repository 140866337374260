import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Tooltip from '@material-ui/core/Tooltip';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import customTableStyle from "../../assets/jss/material-dashboard-react/customTableStyle";
import moment from 'moment';

import { Edit, Delete, MailOutline, Wifi, NotificationsNone, People } from "@material-ui/icons";
import { Translate } from "react-localize-redux";

const DYNAMIC_EMAIL_ALERT_TEMPLATE = "tm_DynamicEmailAlert";

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

const AlarmStatus = ({ selected, role, updateAlarm }) =>
      <Select
       value={selected}
       onChange={(e) => updateAlarm(e.target.value)}
       disabled={selected === "CLEARED" || !role}
     >
        <MenuItem value="ACTIVE">
           <small>ACTIVE</small>
         </MenuItem>
         <MenuItem value="ACKNOWLEDGED">
            <small>ACKNOWLEDGED </small>
          </MenuItem>
          <MenuItem value="CLEARED">
             <small>CLEARED</small>
           </MenuItem>
     </Select>;

const getSorting = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}


class SortableTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      // onSelectAllClick,
      order,
      classes,
      orderBy,
      columnData,
      // numSelected,
      // rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow
          className={classes.tableRowHead}
        >
          {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell> */}
          {columnData
            .filter(column => !column.hidden)
            .map(column => {
            return (
              <TableCell
                className={classes.tableHeadCell}
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                style={{ width: column.width ? column.width : "100%" }}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          {this.props.noActions
          ? null
          :(<TableCell
              className={classes.tableHeadActionsCell}
              style={{ width: "60%" }}
            >
            actions
          </TableCell>)}
        </TableRow>
      </TableHead>
    );
  }
}

SortableTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

// const styles = theme => ({
//   root: {
//     width: '100%',
//     marginTop: theme.spacing.unit * 3,
//   },
//   table: {
//     minWidth: 500,
//   },
//   tableWrapper: {
//     overflowX: 'auto',
//   },
//   tableActionButton: {
//     height: '24px',
//     width: '24px',
//   },
//   tableActionButtonIcon: {
//     height: '18px',
//     width: '18px',
//   }
// });

class CustomPaginationActionsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 10,
      order: props.order || 'desc',
      orderBy: props.orderBy || ''
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
      this.setState({ order, orderBy });
  };

  render() {
    const { classes, columnData, noActions, role } = this.props;
    const data = this.props.data.map(item =>
      columnData.reduce((acc, field) => ({...acc, [field.id]: item.hasOwnProperty(field.id) ? item[field.id] : ''})
      , item.hasOwnProperty('resourceTasks') ? {_id: item.id, _resourceTasks: item.resourceTasks} : {_id: item.id}));
    const { rowsPerPage, page, order, orderBy } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const roles = ['viewer', 'editor', 'admin', 'superadmin'];
    return (
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <SortableTableHead
              // numSelected={selected.length}
               order={order}
               classes={classes}
               columnData={columnData}
               orderBy={orderBy}
               // onSelectAllClick={this.handleSelectAllClick}
               onRequestSort={this.handleRequestSort}
               rowCount={data.length}
               noActions={noActions}
            />
            <TableBody>
              {data
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, row) => {
                return (
                  <TableRow
                    key={row}
                    className={classes.tableRow}
                  >
                    {columnData
                      .filter(data => !data.hidden)
                      .map(data => data.id).map((field, i) =>
                      <TableCell
                        className={classes.tableCell}
                        key={field}
                      >
                        {((columnData.map(data => data.id).indexOf('role') === i ? <Translate id={`users.roles.${roles[n[field]]}`} /> : n[field])
                        && (columnData.map(data => data.id).indexOf('role') === i ? <Translate id={`users.roles.${roles[n[field]]}`} /> : n[field]).length > 20)
                        ?
                          <Tooltip
                              id="tooltip-top"
                              title={(columnData.map(data => data.id).indexOf('role') === i ? <Translate id={`users.roles.${roles[n[field]]}`} /> : n[field]) || ''}
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                              PopperProps={{ style: { pointerEvents: 'none'}, disabled: true }}
                          >
                              <span>{columnData.map(data => data.id).indexOf('role') === i
                                ? roles[n[field]]
                                : typeof n[field] === 'object' ? JSON.stringify(n[field]) : n[field]}
                              </span>
                          </Tooltip>
                        :
                          columnData.map(data => data.id).indexOf('role') === i
                          ? <Translate id={`users.roles.${roles[n[field]]}`} />
                          : (columnData.map(data => data.id).indexOf('status') === i
                            ?
                              <AlarmStatus
                                selected={n[field]}
                                role={role}
                                updateAlarm={(status) => this.props.updateAlarm(n._id, this.props.data[row].severity, status)}
                              />
                            : typeof n[field] === 'object' ? JSON.stringify(n[field]) : n[field])}
                      </TableCell>
                    )}
                    {noActions
                      ? null
                      :
                    (<TableCell className={classes.tableActions}>
                      {n.hasOwnProperty('_resourceTasks') && n._resourceTasks.find(task => task.template === DYNAMIC_EMAIL_ALERT_TEMPLATE)
                      ?  <Tooltip
                          id="tooltip-top"
                          title={<Translate id="tooltips.alerts.setup" />}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          PopperProps={{ style: { pointerEvents: 'none' } }}
                        >
                          <IconButton
                            aria-label="Notifications"
                            className={classes.tableActionButton}
                            onClick={() => this.props.setupAlerts(n._id)}
                          >
                            <NotificationsNone
                              className={
                                classes.tableActionButtonIcon + " " + classes.notifications
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      : null}
                      {this.props.sendCommandButton
                      ?  <Tooltip
                          id="tooltip-top"
                          title={n.downLinkDataSent ? <Translate id="tooltips.sentCommand" data={{ datetime: moment(n.downLinkDataSent).format() }} /> : <Translate id="tooltips.sendCommand" />}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          PopperProps={{ style: { pointerEvents: 'none' } }}
                        >
                          <IconButton
                            aria-label="Send command"
                            className={
                              `${classes.tableActionButton} ${classes.hover} ${n.downlinkData ? classes.sendCommandSet : classes.sendCommand} ${n.downLinkDataSent ? classes.sendCommandSent : classes.sendCommand}`
                            }
                            onClick={() => this.props.sendCommand(n._id)}
                          >
                            <Wifi
                              className={
                                `${classes.tableActionButtonIcon} ${classes.whiteText}`
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      : null
                      }
                      {this.props.messagesButton
                      ?  <Tooltip
                          id="tooltip-top"
                          title={<Translate id="tooltips.messages" />}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          PopperProps={{ style: { pointerEvents: 'none' } }}
                        >
                          <IconButton
                            aria-label="Messages"
                            className={classes.tableActionButton}
                            onClick={() => this.props.viewMessages(n._id)}
                          >
                            <MailOutline
                              className={
                                classes.tableActionButtonIcon + " " + classes.messages
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      : null
                      }
                      {this.props.editButton
                      ?  <Tooltip
                          id="tooltip-top"
                          title={<Translate id="tooltips.edit" />}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          PopperProps={{ style: { pointerEvents: 'none' } }}
                        >
                          <IconButton
                            aria-label="Edit"
                            className={classes.tableActionButton}
                            onClick={() => this.props.editItem(n._id)}
                          >
                            <Edit
                              className={
                                classes.tableActionButtonIcon + " " + classes.edit
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      : null}
                      {this.props.deleteButton
                      ? <Tooltip
                          id="tooltip-top"
                          title={<Translate id="tooltips.delete" />}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          PopperProps={{ style: { pointerEvents: 'none' } }}
                        >
                          <IconButton
                            aria-label="Delete"
                            className={classes.tableActionButton}
                            onClick={() => this.props.deleteItem(n._id)}
                          >
                            <Delete
                              className={
                                classes.tableActionButtonIcon + " " + classes.close
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      : null }
                      {(this.props.signinAsButton && n.loginEmail !== this.props.currentUserEmail)
                      ? <Tooltip
                          id="tooltip-top"
                          title={<Translate id="tooltips.signinAsButton" data={{email: n.loginEmail}} />}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          PopperProps={{ style: { pointerEvents: 'none' } }}
                        >
                          <IconButton
                            aria-label="signinAsButton"
                            className={classes.tableActionButton}
                            onClick={() => this.props.signinAs(n._id)}
                          >
                            <People
                              className={
                                classes.tableActionButtonIcon + " " + classes.signinAs
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      : null }
                    </TableCell>)}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={8}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(customTableStyle)(CustomPaginationActionsTable);
