import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { version } from '../../../package.json';

import logo from '../../assets/img/logo-mcs.png';

import { withStyles } from "@material-ui/core";
import { ItemGrid } from "../../components";

import loginRoutes from "../../routes/login.jsx";
import appStyle from "../../assets/jss/material-dashboard-react/appStyle.jsx";

const switchRoutes = (
  <Switch>
    {loginRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

class Public extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.container}>
            <ItemGrid container alignItems="center" justify="center">
              <ItemGrid xs={10} sm={10} md={3} style={{textAlign: "center"}}>
                <Link to='/login'>
                  <img src={logo} alt='MCS' style={{width: "96px"}} />
                </Link>
              </ItemGrid>
            </ItemGrid>
            <ItemGrid container alignItems="center" justify="center" style={{marginBottom: '1.5rem', fontSize: 'larger'}}>
              <div>
                <span>Manage Resources App</span>
              </div>
            </ItemGrid>
            {switchRoutes}
            <ItemGrid container alignItems="center" justify="center">
              <ItemGrid xs={10} sm={10} md={3}>
                <small>{`v${version}`}</small>
              </ItemGrid>
            </ItemGrid>
          </div>
        </div>
      </div>
    );
  }
}

Public.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withLocalize(withStyles(appStyle)(Public));
