const initialState = {
  isCreatingUser: false,
  createUserError: '',
  isUpdatingUser: false,
  users: [],
  customers: [],
  createCustomerError: '',
  isUpdatingCustomer: false,
  isCreatingCustomer: false,
  resourceTypes: [],
  isCreatingResourceType: false,
  createResourceTypeError: '',
  isUpdatingResourceType: false,
  updateResourceTypeError: '',
  backupInProgress: false,
  restoreInProgress: false,
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CREATE_USER_REQUEST':
      case 'CREATE_USER_WITHOUT_CONFIRMATION_REQUEST':
        return {
          ...state,
          isCreatingUser: true,
          createUserError: '',
        };

      case 'CREATE_USER_SUCCESS':
      case 'CREATE_USER_WITHOUT_CONFIRMATION_SUCCESS':
        return {
          ...state,
          users: [ action.res, ...state.users],
          isCreatingUser: false,
        };

      case 'CREATE_USER_FAILURE':
      case 'CREATE_USER_WITHOUT_CONFIRMATION_FAILURE':
        return {
          ...state,
          isCreatingUser: false,
          createUserError: action.err ? action.err.data.error || action.err.statusText : 'Unknown error',
        };

      case 'GET_USERS_SUCCESS':
        return {
          ...state,
          users: action.res,
        };

      case 'DELETE_USER_SUCCESS':
        return {
          ...state,
          users: state.users.filter(user => user.id !== action.res.id),
        };

      case 'UPDATE_USER_REQUEST':
        return {
          ...state,
          isUpdatingUser: true,
        };

      case 'UPDATE_USER_SUCCESS':
        return {
          ...state,
          users: state.users.map(user => user.id === action.res.id ? action.res : user),
          isUpdatingUser: false,
        };

      case 'UPDATE_USER_FAILURE':
        return {
          ...state,
          isUpdatingUser: false,
        };

      case 'CREATE_CUSTOMER_REQUEST':
        return {
          ...state,
          isCreatingCustomer: true,
          createCustomerError: '',
        };

      case 'CREATE_CUSTOMER_SUCCESS':
        return {
          ...state,
          customers: [ action.res, ...state.customers],
          isCreatingCustomer: false,
        };

      case 'CREATE_CUSTOMER_FAILURE':
        return {
          ...state,
          isCreatingCustomer: false,
          createCustomerError: action.err ? action.err.data.error || action.err.statusText : 'Unknown error',
        };

      case 'GET_CUSTOMERS_SUCCESS':
        return {
          ...state,
          customers: action.res,
        };

      case 'DELETE_CUSTOMER_SUCCESS':
        return {
          ...state,
          customers: state.customers.filter(customer => customer.id !== action.res.id),
          users: state.users.filter(user => !(user.idCompanies.length === 1 && user.idCompanies[0] === action.res.id))
            .map(user => ({...user, idCompanies: user.idCompanies.filter(idCompany => idCompany !== action.res.id)}))
        };

      case 'UPDATE_CUSTOMER_REQUEST':
        return {
          ...state,
          isUpdatingCustomer: true,
        };

      case 'UPDATE_CUSTOMER_SUCCESS':
        return {
          ...state,
          customers: state.customers.map(customer => customer.id === action.res.id ? action.res : customer),
          isUpdatingCustomer: false,
        };

      case 'UPDATE_CUSTOMER_FAILURE':
        return {
          ...state,
          isUpdatingCustomer: false,
        };

      case 'GET_RESOURCE_TYPES_SUCCESS':
        return {
          ...state,
          resourceTypes: action.res,
        };

      case 'CREATE_RESOURCE_TYPE_REQUEST':
        return {
          ...state,
          isCreatingResourceType: true,
          createResourceTypeError: '',
        };

      case 'CREATE_RESOURCE_TYPE_SUCCESS':
        return {
          ...state,
          resourceTypes: [ action.res, ...state.resourceTypes],
          isCreatingResourceType: false,
        };

      case 'CREATE_RESOURCE_TYPE_FAILURE':
        return {
          ...state,
          isCreatingResourceType: false,
          createResourceTypeError: action.err ? action.err.data.error || action.err.statusText : 'Unknown error',
        };

      case 'UPDATE_RESOURCE_TYPE_REQUEST':
        return {
          ...state,
          isUpdatingResourceType: true,
          updateResourceTypeError: '',
        };

      case 'UPDATE_RESOURCE_TYPE_SUCCESS':
        return {
          ...state,
          resourceTypes: state.resourceTypes.map(resourceType => resourceType.id === action.res.id
                ? action.res
                : resourceType),
          isUpdatingResourceType: false,
        };

      case 'UPDATE_RESOURCE_TYPE_FAILURE':
        return {
          ...state,
          isUpdatingResourceType: false,
          updateResourceTypeError: action.err ? action.err.data.error || action.err.statusText : 'Unknown error',
        };

      case 'BACKUP_DB_REQUEST':
        return {
          ...state,
          backupInProgress: true
        };

      case 'BACKUP_DB_COMPLETE':
        return {
          ...state,
          backupInProgress: false
        };

      case 'RESTORE_DB_REQUEST':
        return {
          ...state,
          restoreInProgress: true
        };

      case 'RESTORE_DB_SUCCESS':
      case 'RESTORE_DB_FAILURE':
        return {
          ...state,
          restoreInProgress: false
        };

      default:
        return {...state};
    }
}

export default adminReducer;
