const initialState = {
  appIsBusy: 0,
  localizationEnabled: false,
};

const appStateReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'APP_IS_BUSY':
        return {
            ...state,
            appIsBusy: state.appIsBusy + 1,
        };

      case 'APP_IS_NOT_BUSY':
        return {
            ...state,
            appIsBusy: state.appIsBusy - 1 < 0 ? 0 : state.appIsBusy - 1,
        };
      case 'ENABLE_LOCALIZATION':
        return {
          ...state,
          localizationEnabled: true,
        };
      case 'DISABLE_LOCALIZATION':
        return {
          ...state,
          localizationEnabled: false,
        };
      default:
            return {...state};
    }
}

export default appStateReducer;
