import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { withStyles } from "@material-ui/core";

import { Header, Sidebar, Snackbars } from "../../components";

import dashboardRoutes from "../../routes/dashboard.jsx";

import appStyle from "../../assets/jss/material-dashboard-react/appStyle.jsx";

import image from "../../assets/img/sidebar-IoT.jpg";
import logo from "../../assets/img/logo-mcs.png";

import Profile from "../../views/Forms/Profile.jsx";
import { isResourceAssigned } from '../../utils';
import {
  signout
} from "../../actions/session";

import {
  getResources,
  getAlarms,
  getProfile,
  updateProfile,
  closeSnackbar,
} from "../../actions/user";

import {
  getUsers,
  getCustomers,
  getResourceTypes,
} from "../../actions/admin";

const SwitchRoutes = ({routes}) => (
  <Switch>
    {routes.reduce((acc, route) =>
        route.path === '/resources'
          ? [...acc, {path: `${route.path}/:id`, component: route.component}, route]
          : [...acc, route]
      , [])
      .map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      profileOpen: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  componentDidMount() {
    if(navigator.platform.indexOf('Win') > -1){
      // eslint-disable-next-line
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    this.props.getResources();
    this.props.getProfile();
    if(this.props.role === 2 || this.props.role === 3) {
      this.props.getUsers();
      this.props.getCustomers();
      this.props.getAlarms();
    }
    if(this.props.role === 3) {
      this.props.getResourceTypes();
    }
  }
  componentDidUpdate() {
    this.refs.mainPanel.scrollTop = 0;
  }

  _handleClose = () => {
    this.setState({
      profileOpen: false,
    });
  }

  _handleOpen = () => {
    this.setState({
      profileOpen: true
    });
  }

  render() {
    const routes = dashboardRoutes.filter(item => typeof item.accessLevel.find(level => level === this.props.role) !== 'undefined')
        .map(route => route.path === "/resources" ? {...route, nested: this.props.resourceTypes.map(nested => ({
        path: `${route.path}/${nested.id}`,
        sidebarName: nested.menuText || nested.name,
        icon: route.nestedIcon,
      })) } : route);
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"Resources"}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Header
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            loginEmail={this.props.profile.loginEmail}
            openProfile={this._handleOpen}
            {...rest}
          />
            <div className={classes.content}>
              <div className={classes.container}>
                <SwitchRoutes
                  routes={routes}
                />
              </div>
            </div>
          {/* <Footer /> */}
          {this.state.profileOpen
            && <Profile
                edit
                title="Edit Profile"
                enableReinitialize={true}
                profile={this.props.profile}
                isOpen={this.state.profileOpen}
                handleClose={this._handleClose}
                updateProfile={this.props.updateProfile}
                isUpdatingProfile={this.props.isUpdatingProfile}
                updateProfileError={this.props.updateProfileError}
              />}
        </div>
        <Snackbars
          snackbars={this.props.snackbars}
          closeSnackbar={this.props.closeSnackbar}
        />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  localizationEnabled: state.appState.localizationEnabled,
  profile: state.user.profile,
  isUpdatingProfile: state.user.isUpdatingProfile,
  updateProfileError: state.user.updateProfileError,
  snackbars: state.user.snackbars,
  role: state.session.role,
  resourceTypes: state.user.resources.reduce((acc, resource) =>
    !isResourceAssigned(resource) || acc.find(found => resource.resourceTypeId === found.id)
    ? [...acc]
    : [...acc, {id: resource.resourceTypeId, name: resource.resourceType.name, menuText: resource.resourceType.menuText}] , []),
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  signout,
  getResources,
  getUsers,
  getCustomers,
  getAlarms,
  getProfile,
  updateProfile,
  closeSnackbar,
  getResourceTypes,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appStyle)(Dashboard));
