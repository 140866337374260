import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { confirmable } from 'react-confirm';
import { createConfirmation } from 'react-confirm';

class Confirm extends React.Component {
    render() {
      const {show, proceed, dismiss, confirmation, options} = this.props;
      return (
        <div>
          <Dialog open={show} onBackdropClick={dismiss} className={options.className || ''}>
            <DialogTitle>{options.title || ''}</DialogTitle>
            <DialogContent>
              {confirmation}
            </DialogContent>
            <DialogActions>
              {!options.noButton && <Button disabled={options.showInput && !this.state.inputValue} color="secondary" onClick={() => proceed()}>{options.confirmButtonText || 'Remove'}</Button>}
              {!options.noCancelButton && <Button className="ml-3" color="default" onClick={() => dismiss()}>Cancel</Button>}
            </DialogActions>
          </Dialog>
        </div>
      );
    }
}


export default (confirmation, options = {}) => (
  createConfirmation(confirmable(Confirm))({ confirmation, options })
);
