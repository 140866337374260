import React, { Component }  from "react";
import { Formik, Field, Form } from 'formik';
import { ref as yupRef, object as yupObject, string as yupString, boolean as yupBoolean } from 'yup';
import { Grid, CircularProgress, FormControl, FormLabel, DialogActions } from "@material-ui/core";
import { Translate } from "react-localize-redux";

import {
  Button,
  CustomInput,
  ItemGrid,
  CustomDialog,
} from "../../components";

const ProfileFormVS = yupObject().shape({
  displayChangePassword: yupBoolean(),
  name: yupString()
    .required('required'),
  description: yupString(),
  old_password: yupString()
	.when('displayChangePassword', {
		is: true,
		then: yupString()
			.required('required'),
	}),
  new_password: yupString()
  	.when('displayChangePassword', {
  			is: true,
  			then: yupString()
  		    .required('required'),
        }),
  conf_password: yupString()
    .when('displayChangePassword', {
    		is: true,
    		then: yupString()
    	    .oneOf([yupRef('new_password', null)],  'match')
    	    .required('required'),
    	}),
});

const ProfileDialog = ({
  profile,
  isOpen,
  updateProfile,
  isUpdatingProfile,
  handleClose,
  title,
  enableReinitialize,
  updateProfileError,
}) =>
  <Formik
  	initialValues={{
      name: profile.name,
      description: profile.description,
      displayChangePassword: false,
      old_password: '',
      new_password: '',
      conf_password: '',
    }}
  	validationSchema={ProfileFormVS}
    isInitialValid={props => {
      ProfileFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={(values, { props }) => {
    		const data = values.displayChangePassword
        ? {
          name: values.name,
          description: values.description,
          new_password: values.new_password,
          old_password: values.old_password,
    		}
        : {
          name: values.name,
          description: values.description,
        };
    		updateProfile(data, () => handleClose());
    	}
    }
    render={props => <ProfileForm {...props}
      profile={profile}
      isOpen={isOpen}
      handleClose={handleClose}
      isUpdatingProfile={isUpdatingProfile}
      title={title}
      updateProfileError={updateProfileError}
    />}
  />

class ProfileForm extends Component {
  render() {
    const {
      errors,
      touched,
      isValid,
    } = this.props;
    return (
        <CustomDialog
          title={this.props.title}
          handleClose={this.props.handleClose}
          open={this.props.isOpen}
        >
            <Form className="form">
              <Grid container justify="flex-end">
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="name"
                    placeholder={<Translate id="profile.name" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="profile.name" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isUpdatingProfile
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.name && touched.name &&
                    <FormLabel
                      error
                    >
                      <Translate id={`profile.errors.name.${errors.name}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="description"
                    placeholder={<Translate id="profile.description" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="profile.description" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isUpdatingProfile
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12} style={{display: "flex", alignItems: "center"}}>
                  <Field
                    name="displayChangePassword"
                    type="checkbox"
                  />
                  <FormLabel>{<Translate id="profile.change_password" />}</FormLabel>
                </ItemGrid>
                {this.props.values.displayChangePassword
                ?
                <Grid container>
                  <ItemGrid xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <Field
                      name="new_password"
                      render={({field, form}) => (
                        <CustomInput
                         labelText={<Translate id="profile.new_password" />}
                         inputProps={{
                           "type": "password",
                           "name": field.name,
                           "value": field.value,
                           "onChange": form.handleChange,
                           "onBlur": form.handleBlur,
                           "disabled": this.props.isUpdatingProfile
                         }}
                         formControlProps={{
                         }}
                       />
                      )}
                    />
                    {errors.new_password && touched.new_password &&
                      <FormLabel
                        error
                      >
                        <Translate id={`profile.errors.password.${errors.new_password}`} />
                      </FormLabel>}
                  </FormControl>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <Field
                      name="conf_password"
                      render={({field, form}) => (
                        <CustomInput
                         labelText={<Translate id="profile.conf_password" />}
                         inputProps={{
                           "type": "password",
                           "name": field.name,
                           "value": field.value,
                           "onChange": form.handleChange,
                           "onBlur": form.handleBlur,
                           "disabled": this.props.isUpdatingProfile
                         }}
                         formControlProps={{
                         }}
                       />
                      )}
                    />
                    {errors.conf_password && touched.conf_password &&
                      <FormLabel
                        error
                      >
                        <Translate id={`profile.errors.password.${errors.conf_password}`} />
                      </FormLabel>}
                  </FormControl>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <Field
                      name="old_password"
                      render={({field, form}) => (
                        <CustomInput
                         labelText={<Translate id="profile.old_password" />}
                         inputProps={{
                           "type": "password",
                           "name": field.name,
                           "value": field.value,
                           "onChange": form.handleChange,
                           "onBlur": form.handleBlur,
                           "disabled": this.props.isUpdatingProfile
                         }}
                         formControlProps={{
                         }}
                       />
                      )}
                    />
                    {errors.old_password && touched.old_password &&
                      <FormLabel
                        error
                      >
                        <Translate id={`profile.errors.password.${errors.old_password}`} />
                      </FormLabel>}
                  </FormControl>
                  </ItemGrid>
                </Grid> : null}
                <DialogActions>
                  <Button
                    onClick={this.props.handleClose}
                  >
                    <Translate id="profile.cancel" />
                  </Button>
                  {this.props.isUpdatingProfile
                    ? <div><CircularProgress /></div>
                    :  <Button
                          color="success"
                          type="submit"
                          disabled={!isValid}
                        >
                          <Translate id="profile.save" />
                      </Button>
                    }
                </DialogActions>
                </Grid>
                <FormLabel
                  error
                >
                  {this.props.updateProfileError}
                </FormLabel>
              </Form>
        </CustomDialog>
    );
  }
}

export default ProfileDialog;
