import React, { Component }  from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid } from "@material-ui/core";
import { Translate, getTranslate } from "react-localize-redux";

import modalConfirm from '../../components/Dialog/confirm';

import {
  RegularCard,
  Button,
  ItemGrid,
  CustomPaginationTable,
} from "../../components";

import UserDialog from '../Forms/UserDialog.jsx'
import EditUserDialog from '../Forms/EditUserDialog.jsx'

import {
  createUser,
  createUserWithoutConfirmation,
  deleteUser,
  getUser,
  updateUser,
  signinAs,
} from "../../actions/admin";

const usersTableParameters = [
  {
    roles: [2, 3],
    columnData: [
      { id: 'loginEmail', numeric: false, disablePadding: true, label: 'loginEmail' },
      { id: 'name', numeric: false, disablePadding: true, label: 'name' },
      { id: 'Company', numeric: false, disablePadding: true, label: 'Customer' },
      { id: 'role', numeric: false, disablePadding: true, width: "40%", label: 'role' },
    ]
  },
];

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      editDialogOpen: false,
      user: {},
    };
  }

  _handleClose = () => {
    this.setState({
      dialogOpen: false,
      editDialogOpen: false,
    });
  }

  _handleOpen = () => {
    this.setState({
      dialogOpen: true
    });
  }

  _handleEdit = userId => {
    this.props.getUser(userId, user => {
      this.setState({
        editDialogOpen : true,
        user
      })
    });
  }

  _deleteUser = (userId, { message, title, confirmButtonText }) => {
    modalConfirm(message, {title, confirmButtonText, noCancelButton: true})
      .then(result => {
        this.props.deleteUser(userId);
      }, cancel => {});
  }

  render() {
    const tableParams = usersTableParameters.find(parameters => parameters.roles.find(role => role === this.props.role));
    const { translate } = this.props;
    return (
      <div>
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={translate("users.title")}
              headerColor="orange"
              cardSubtitle={
                <Button
                  style={{margin: "0"}}
                  color="success"
                  onClick={this._handleOpen}
                >
                  <Translate id="users.add" />
                </Button>
              }
              content={
                <CustomPaginationTable
                  data={this.props.users.map(user => ({
                      ...user,
                      Company: this.props.customers.find(customer => user.idCompanies.find(id => customer.id === id))
                      ? this.props.customers
                        .filter(customer => user.idCompanies
                        .find(id => customer.id === id))
                        .map(customer => customer.name)
                        .join(', ')
                      : ''
                    }))}
                  columnData={tableParams.columnData}
                  deleteItem={(id) => this._deleteUser(id, {
                    message: translate("users.modal.delete.message", { name: this.props.users.find(user => user.id === id).name }),
                    title: translate("users.modal.delete.title"),
                    confirmButtonText: translate("users.modal.delete.confirmButtonText")
                  })}
                  editItem={this._handleEdit}
                  deleteButton
                  editButton
                  signinAsButton={this.props.role === 3}
                  signinAs={this.props.signinAs}
                  currentUserEmail={this.props.currentUserEmail}
                />
              }
            />
          </ItemGrid>
        </Grid>
        {this.state.dialogOpen
          && <UserDialog
              user={{
                  loginEmail: '',
                  password: '',
                  confirmPassword: '',
                  name: '',
                  description: '',
                  idCompanies: [],
                  role: 0,
              }}
              new
              title={translate("users.dialog.create_title")}
              role={this.props.role}
              isOpen={this.state.dialogOpen}
              customers={this.props.customers}
              handleClose={this._handleClose}
              createUser={this.props.createUser}
              createUserWithoutConfirmation={this.props.createUserWithoutConfirmation}
              isCreatingUser={this.props.isCreatingUser}
              createUserError={this.props.createUserError}
              sendEmail={true}
            />}
        {this.state.editDialogOpen
          && <EditUserDialog
              title={translate("users.dialog.edit_title")}
              role={this.props.role}
              enableReinitialize={true}
              user={this.state.user}
              isOpen={this.state.editDialogOpen}
              customers={this.props.customers}
              handleClose={this._handleClose}
              updateUser={this.props.updateUser}
              isUpdatingUser={this.props.isUpdatingUser}
              updateUserError={this.props.updateUserError}
            />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize),
  isCreatingUser: state.admin.isCreatingUser,
  isUpdatingUser: state.admin.isUpdatingUser,
  createUserError: state.admin.createUserError,
  updateUserError: state.admin.updateUserError,
  users: state.admin.users,
  role: state.session.role,
  currentUserEmail: state.user.profile.loginEmail,
  customers: state.admin.customers,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  createUser,
  deleteUser,
  getUser,
  updateUser,
  signinAs,
  createUserWithoutConfirmation,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Users);
