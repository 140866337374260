import httpService from '../services/httpService';
import API_CONSTANTS from '../constants/apiConstants';
import { toSearchParams } from '../utils';

const getResources = () => {
  return httpService.get(API_CONSTANTS.RESOURCES);
};

const getAlarms = () => {
  return httpService.get(API_CONSTANTS.ALARMS);
};

const updateResource = (resourceId, data) => {
  return httpService.put(API_CONSTANTS.RESOURCE, {resourceId, data});
};

const updateEmailAlerts = (resourceId, data) => {
  return httpService.put(API_CONSTANTS.EMAIL_ALERTS, {resourceId, data});
};

const getProfile = () => {
  return httpService.get(API_CONSTANTS.PROFILE);
};

const updateProfile = data => {
  return httpService.put(API_CONSTANTS.PROFILE, toSearchParams(data));
};

const sendResetPasswordEmail = loginEmail => {
  return httpService.post(API_CONSTANTS.FORGOT_PASSWORD, toSearchParams(loginEmail));
};

const setNewPassword = payload => {
  return httpService.post(API_CONSTANTS.SET_PASSWORD, toSearchParams(payload));
};

const deleteAlarm = alarmId => {
  return httpService.delete(API_CONSTANTS.ALARM, {params: {alarmId}});
};

const updateAlarm = (alarmId, data) => {
  return httpService.put(API_CONSTANTS.ALARM, {alarmId, data});
};

const getMessages = resourceId => {
  return httpService.get(API_CONSTANTS.MESSAGES, {params: {resourceId}});
};

const sendCommand = (resourceId, commandInHex) => {
  return httpService.put(API_CONSTANTS.SEND_COMMAND, { resourceId, commandInHex });
}

export default {
  getResources,
  getAlarms,
  updateResource,
  updateEmailAlerts,
  getProfile,
  updateProfile,
  sendResetPasswordEmail,
  setNewPassword,
  deleteAlarm,
  updateAlarm,
  getMessages,
  sendCommand,
};
