import httpService from '../services/httpService';
import API_CONSTANTS from '../constants/apiConstants';
import { toSearchParams } from '../utils';

const signin = data => {
  return httpService.post(API_CONSTANTS.SIGNIN, toSearchParams(data));
};

export default {
  signin
};
