import translations from '../translations';
import { renderToStaticMarkup } from 'react-dom/server';

export default (initialize, addTranslationForLanguage, setActiveLanguage) => {
  initialize({
        languages: translations.map(translation => ({name: translation.name, code: translation.code})),
        options: {
          renderToStaticMarkup,
          onMissingTranslation: ({ defaultTranslation }) => defaultTranslation
        }
      });
      translations.forEach(translation => {
        addTranslationForLanguage(translation.translation, translation.code);
      });
      const language = localStorage.getItem('language');
      if(language) {
        setActiveLanguage(language);
      }
}
