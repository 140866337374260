import httpService from '../services/httpService';
import API_CONSTANTS from '../constants/apiConstants';
import { toSearchParams } from '../utils';

const saveKeys = data => {
  return httpService.post(API_CONSTANTS.KEYS, toSearchParams(data));
};

const getKeys = () => {
  return httpService.get(API_CONSTANTS.KEYS);
};

const getWaylayResourcesList = () => {
  return  httpService.get(API_CONSTANTS.WAYLAY_RESOURCES);
}

const createUser = data => {
  return httpService.post(API_CONSTANTS.USER, {data});
};

const createUserWithoutConfirmation = data => {
  return httpService.post(API_CONSTANTS.CREATE_USER, {data});
};

const getUsers = () => {
  return httpService.get(API_CONSTANTS.USERS);
};

const deleteUser = userId => {
  return httpService.delete(API_CONSTANTS.USER, {params: {userId}});
};

const getUser = userId => {
  return httpService.get(API_CONSTANTS.USER, {params: {userId}});
};

const updateUser = (userId, data) => {
  return httpService.put(API_CONSTANTS.USER, {userId, data});
};

const createCustomer = data => {
  return httpService.post(API_CONSTANTS.CUSTOMER, toSearchParams(data));
};

const getCustomers = () => {
  return httpService.get(API_CONSTANTS.CUSTOMERS);
};

const deleteCustomer = customerId => {
  return httpService.delete(API_CONSTANTS.CUSTOMER, {params: {customerId}});
};

const getCustomer = customerId => {
  return httpService.get(API_CONSTANTS.CUSTOMER, {params: {customerId}});
};

const updateCustomer = (customerId, data) => {
  return httpService.put(API_CONSTANTS.CUSTOMER, toSearchParams({customerId, ...data}));
};

const getResourceTypes = () => {
  return httpService.get(API_CONSTANTS.RESOURCE_TYPES);
};

const getResourceType = resourceTypeId => {
  return httpService.get(API_CONSTANTS.RESOURCE_TYPE, {params: {resourceTypeId}});
};

const createResourceType = data => {
  return httpService.post(API_CONSTANTS.RESOURCE_TYPE, {data});
};

const updateResourceType = (resourceTypeId, data) => {
  return httpService.put(API_CONSTANTS.RESOURCE_TYPE, {resourceTypeId, data});
};

const signinAs = userId => {
  return httpService.post(API_CONSTANTS.SIGNIN_AS, {userId});
};

const backupDB = () => {
  return httpService.get(API_CONSTANTS.BACKUP_DB, { responseType: 'blob' });
};

const getToken = () => {
  return httpService.get(API_CONSTANTS.TOKEN);
};

const restoreDB = (backupFile) => {
  const data = new FormData();
  data.append('file', backupFile, backupFile.name)
  return httpService.post(API_CONSTANTS.RESTORE_DB, data);
}

export default {
  saveKeys,
  getKeys,
  getWaylayResourcesList,
  createUser,
  getUsers,
  deleteUser,
  getUser,
  updateUser,
  createCustomer,
  getCustomers,
  deleteCustomer,
  getCustomer,
  updateCustomer,
  getResourceTypes,
  getResourceType,
  createResourceType,
  updateResourceType,
  createUserWithoutConfirmation,
  signinAs,
  backupDB,
  getToken,
  restoreDB,
};
