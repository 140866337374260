import React, { Component, Fragment }  from "react";
import { Formik, Field, Form, FieldArray } from 'formik';
import { object as yupObject, string as yupString, array as yupArray, number as yupNumber, boolean as yupBoolean } from 'yup';
import { Grid, CircularProgress, FormControl, FormLabel, DialogActions, Select, MenuItem, Button as CoreButton } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import moment from 'moment-timezone';

import modalConfirm from '../../components/Dialog/confirm';
import customInputStyle from "../../assets/jss/material-dashboard-react/customInputStyle";

import {
  Button,
  CustomInput,
  ItemGrid,
  CustomDialog,
  // Information,
} from "../../components";

import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Tooltip,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import FilterNone from '@material-ui/icons/FilterNone';

const processTemplatesSettings = data => {
  return {...data, templates: data.templates.map(template => ({
    templateName: template.templateName,
    type: template.type,
    ...(template.type === 'scheduled' && { cron: template.cron }),
    ...(template.type === 'scheduled' && template.timeZone && { timeZone: template.timeZone }),
    ...(template.type === 'periodic' && { frequency: template.frequency }),
    ...(template.pollingFixedRate !== false && { pollingFixedRate: template.pollingFixedRate }),
    ...(template.resetObservations !== true && { resetObservations: template.resetObservations }),
    ...(template.parallel !== true && { parallel: template.parallel }),
  }))}
}

const templates = ["tm_DynamicEmailAlert", "monitoring"];

const types = ['onetime', 'scheduled', 'reactive', 'periodic'];

const emptyTemplateConfig = {
  templateName: templates[0],
  type: types[2],
  cron: "0 1 0 * * ?",
  timeZone: "",
  frequency: 600000,
  pollingFixedRate: false,
  resetObservations: true,
  parallel: true,  
};

const ResourceTypeDialogFormVS = yupObject().shape({
  name: yupString()
    .required('required'),
  description: yupString(),
  menuText: yupString(),
  ResourceTypeCustomer: yupString()
    .required(),
  viewFields: yupArray().of(yupString()),
  editFields: yupArray().of(yupString()),
  templates: yupArray().of(yupObject().shape({
    templateName: yupString()
    .required('required'),
    type: yupString()
    .required('required')
    .oneOf(types),
    cron: yupString(),
    timeZone: yupString(),
    frequency: yupNumber(),
    pollingFixedRate: yupBoolean(),
    resetObservations: yupBoolean(),
    parallel: yupBoolean(),
    
  }))
});

const ResourceTypeDialog = ({
  resourceType,
  isOpen,
  submitResourceType,
  isProcessingResourceType,
  handleClose,
  title,
  enableReinitialize,
  role,
  customers,
  resourceTypeError,
  showWarning,
  classes,
}) =>
  <Formik
  	initialValues={{ ...resourceType, templates: resourceType.hasOwnProperty('templates')
    ? resourceType.templates.map(template => ({
        templateName: template.templateName,
        type: template.type,
        cron: template.hasOwnProperty('cron') ? template.cron : emptyTemplateConfig.cron,
        timeZone: template.hasOwnProperty('timeZone') ? template.timeZone : emptyTemplateConfig.timeZone,
        frequency: template.hasOwnProperty('frequency') ? template.frequency : emptyTemplateConfig.frequency,
        pollingFixedRate: template.hasOwnProperty('pollingFixedRate') ? template.pollingFixedRate : emptyTemplateConfig.pollingFixedRate,
        resetObservations: template.hasOwnProperty('resetObservations') ? template.resetObservations : emptyTemplateConfig.resetObservations,
        parallel: template.hasOwnProperty('parallel') ? template.parallel : emptyTemplateConfig.parallel,
      }))
    : [] }}
  	validationSchema={ResourceTypeDialogFormVS}
    isInitialValid={props => {
      ResourceTypeDialogFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={(values, { props }) => {
    		const data = {
          ...resourceType,
          name: values.name,
          description: values.description,
          ResourceTypeCustomer: values.ResourceTypeCustomer,
          viewFields: values.viewFields,
          editFields: values.editFields,
          menuText: values.menuText,
          templates: values.templates,
    		};
        const submit = showWarning
          ? () => modalConfirm(`Entered values will be inherited by all resources that are linked to this resource type!`,
                    {title: "Attention:", confirmButtonText: 'Save', noCancelButton: false})
                  .then(result => {
                    submitResourceType(resourceType.id, processTemplatesSettings(data), (e, _) => handleClose(e, _, true));
                  }, cancel => {})
      		: () => submitResourceType(processTemplatesSettings(data), (e, _) => handleClose(e, _, true));
        submit();
    	}
    }
    render={props => <ResourceTypeForm {...props}
      resourceType={resourceType}
      isOpen={isOpen}
      handleClose={handleClose}
      isProcessingResourceType={isProcessingResourceType}
      title={title}
      role={role}
      customers={customers}
      resourceTypeError={resourceTypeError}
      classes={classes}
    />}
  />

class ResourceTypeForm extends Component {
  _handleChange = (e) => {
    this.props.setFieldValue(e.target.name, e.target.value);
  }
  
  _selectType = (e, i) => {
    this.props.setFieldValue(e.target.name, e.target.value);
  }

  render() {
    const {
      errors,
      touched,
      isValid,
      values,
      classes,
    } = this.props;
    return (
        <CustomDialog
          title={this.props.title}
          handleClose={this.props.handleClose}
          open={this.props.isOpen}
          maxWidth='sm'
        >
            <Form className="form">
              <Grid container justify="flex-end">
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="name"
                    placeholder={<Translate id="resource_types.dialog.name" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="resource_types.dialog.name" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isProcessingResourceType
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.name && touched.name &&
                    <FormLabel
                      error
                    >
                      <Translate id={`resource_types.dialog.errors.name.${errors.name}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="description"
                    placeholder={<Translate id="resource_types.dialog.description" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="resource_types.dialog.description" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isProcessingResourceType
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.description && touched.description &&
                    <FormLabel
                      error
                    >
                      {errors.description}
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={4} style={{display: "flex", alignItems: "center"}}>
                  <FormLabel>Customer:</FormLabel>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={8}>
                <FormControl fullWidth>
                  <Field
                    name="ResourceTypeCustomer"
                    value={this.props.values.ResourceTypeCustomer}
                    placeholder={<Translate id="resource_types.dialog.customer" />}
                    render={({field, form}) => (
                      <Select
                       name={field.name}
                       value={field.value}
                       onChange={form.handleChange}
                     >
                       {this.props.customers.map(customer =>
                         <MenuItem value={customer.name} key={customer.id}>
                           <em>{customer.name}</em>
                         </MenuItem>
                       )}
                     </Select>
                    )}
                  />
                  {errors.ResourceTypeCustomer && touched.ResourceTypeCustomer &&
                    <FormLabel
                      error
                    >
                      {errors.ResourceTypeCustomer}
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                {this.props.role === 3 &&
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="menuText"
                    placeholder={<Translate id="resource_types.dialog.menuText" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="resource_types.dialog.menuText" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isProcessingResourceType
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.description && touched.description &&
                    <FormLabel
                      error
                    >
                      {errors.description}
                    </FormLabel>}
                </FormControl>
                </ItemGrid>}
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <FieldArray
                    name="viewFields"
                    render={arrayHelpers => (
                      values.viewFields && values.viewFields.length > 0
                      ? (values.viewFields.map((field, i) =>
                      <div key={i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Field
                            name={`viewFields.${i}`}
                            render={({field, form}) => (
                              <CustomInput
                               labelText={<Translate id="resource_types.dialog.viewField" />}
                               inputProps={{
                                 "name": field.name,
                                 "value": field.value,
                                 "onChange": form.handleChange,
                                 "onBlur": form.handleBlur,
                                 "disabled": this.props.isUpdatingResourceType
                               }}
                               formControlProps={{
                                 "style": { width: "80%" }
                               }}
                             />
                            )}
                          />
                          <div>
                            <CoreButton
                              color="secondary"
                              style={{minWidth: "27px", width: "27px", fontWeight: "bold"}}
                              onClick={() => arrayHelpers.remove(i)}
                            >
                              -
                            </CoreButton>
                            <CoreButton
                              style={{minWidth: "27px", width: "27px", marginLeft: "2px", color: "green", fontWeight: "bold"}}
                              onClick={() => arrayHelpers.insert(i + 1, '')}
                            >
                              +
                            </CoreButton>
                          </div>
                        </div>
                      )
                    ) : (<Button color="info" style={{width: "100%"}} onClick={() => arrayHelpers.push('')}>
                            <Translate id="resource_types.dialog.addViewField" />
                        </Button>)
                    )}
                  />
                  {errors.viewFields && touched.viewFields &&
                    <FormLabel
                      error
                    >
                      {errors.viewFields}
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                {this.props.role === 3 &&
                <Fragment>
                  <ItemGrid xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                      <FieldArray
                        name="editFields"
                        render={arrayHelpers => (
                          values.editFields && values.editFields.length > 0
                          ? (values.editFields.map((field, i) =>
                          <div key={i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <Field
                                name={`editFields.${i}`}
                                render={({field, form}) => (
                                  <CustomInput
                                   labelText={<Translate id="resource_types.dialog.editField" />}
                                   inputProps={{
                                     "name": field.name,
                                     "value": field.value,
                                     "onChange": form.handleChange,
                                     "onBlur": form.handleBlur,
                                     "disabled": this.props.isUpdatingResourceType,
                                   }}
                                   formControlProps={{
                                     "style": { width: "80%" }
                                   }}
                                 />
                                )}
                              />
                              <div>
                                <CoreButton
                                  color="secondary"
                                  style={{minWidth: "27px", width: "27px", fontWeight: "bold"}}
                                  onClick={() => arrayHelpers.remove(i)}
                                >
                                  -
                                </CoreButton>
                                <CoreButton
                                  style={{minWidth: "27px", width: "27px", marginLeft: "2px", color: "green", fontWeight: "bold"}}
                                  onClick={() => arrayHelpers.insert(i + 1, '')}
                                >
                                  +
                                </CoreButton>
                              </div>
                            </div>
                          )

                          )
                          : (<Button color="info" style={{width: "100%"}} onClick={() => arrayHelpers.push('')}>
                                <Translate id="resource_types.dialog.addEditField" />
                            </Button>)
                        )}
                      />
                      {errors.viewFields && touched.viewFields &&
                        <FormLabel
                          error
                        >
                          {errors.viewFields}
                        </FormLabel>}
                    </FormControl>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} md={12}>
                  <FormLabel><Translate id="resource_types.dialog.templatesLabel" /></FormLabel>
                  <FormControl fullWidth>
                    <FieldArray
                      name="templates"
                      render={arrayHelpers => (
                        <div>
                          {values.templates && values.templates.length > 0 ? (
                            values.templates.map((template, i) => (
                            <div key={i}>
                             <ExpansionPanel key={i}>
                               <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{height: "36px"}}>
                                 <div style={{display: "flex", alignItems: "center", width: "50%"}}>{`${template.templateName}`}</div>
                                 <div style={{width: "50%", textAlign: "end"}}>
                                   <Tooltip
                                       id="tooltip-top"
                                       title={<Translate id="tooltips.templates.remove" />}
                                       placement="top"
                                       PopperProps={{ style: { pointerEvents: 'none' } }}
                                    >
                                     <IconButton
                                       color="secondary"
                                       onClick={(e) => {
                                         e.stopPropagation();
                                         arrayHelpers.remove(i);
                                       }}
                                     >
                                        <Remove />
                                     </IconButton>
                                   </Tooltip>
                                   <Tooltip
                                       id="tooltip-top"
                                       title={<Translate id="tooltips.templates.add" />}
                                       placement="top"
                                       PopperProps={{ style: { pointerEvents: 'none' } }}
                                    >
                                     <IconButton
                                       style={{color: "green"}}
                                       onClick={(e) => {
                                         e.stopPropagation();
                                         arrayHelpers.insert(i + 1, emptyTemplateConfig);
                                       }}
                                     >
                                       <Add />
                                     </IconButton>
                                   </Tooltip>
                                   <Tooltip
                                       id="tooltip-top"
                                       title={<Translate id="tooltips.templates.duplicate" />}
                                       placement="top"
                                       PopperProps={{ style: { pointerEvents: 'none' } }}
                                    >
                                     <IconButton
                                       onClick={(e) => {
                                         e.stopPropagation();
                                         arrayHelpers.insert(i + 1, {...values.templates[i]});
                                       }}
                                     >
                                       <FilterNone />
                                     </IconButton>
                                   </Tooltip>
                                 </div>
                               </ExpansionPanelSummary>
                               <ExpansionPanelDetails>
                                <Grid container justify="flex-end">
                                <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                   <Field
                                     name={`templates.${i}.templateName`}
                                     render={({field, form}) => (
                                       <FormControl required fullWidth>
                                         <InputLabel><Translate id="resource_types.dialog.templates.templateName.label" /></InputLabel>
                                         <Select
                                          inputProps={{
                                            "name": field.name,
                                            "value": field.value,
                                            "onChange": form.handleChange,
                                            "disabled": this.props.isUpdatingResourceType
                                          }}
                                          SelectDisplayProps={{
                                            "style": {fontSize: 'small'}
                                          }}
                                         >
                                           {templates.find(template => template === values.templates[i].templateName)
                                             ? templates.map(type =>
                                                 <MenuItem value={type} key={type} style={{fontSize: 'small'}}>
                                                   <em>{type}</em>
                                                 </MenuItem>
                                               )
                                             : [...templates, values.templates[i].templateName].map(type =>
                                                 <MenuItem value={type} key={type} style={{fontSize: 'small'}}>
                                                   <em>{type}</em>
                                                 </MenuItem>
                                               )}
                                         </Select>
                                       </FormControl>
                                     )}
                                   />
                                   {errors.templates && errors.templates[i] && errors.templates[i].templateName
                                   && touched.templates && touched.templates[i] && touched.templates[i].templateName &&
                                     <FormLabel
                                       error
                                     >
                                       <Translate id={`resource_types.dialog.templates.templateName.${errors['templates'][i].templateName}`} />
                                     </FormLabel>}
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                   <Field
                                     name={`templates.${i}.type`}
                                     render={({field, form}) => (
                                       <FormControl required fullWidth>
                                         <InputLabel><Translate id="resource_types.dialog.templates.type.label" /></InputLabel>
                                         <Select
                                          inputProps={{
                                            "name": field.name,
                                            "value": field.value,
                                            "onChange": (e) => this._selectType(e, i),
                                            "disabled": this.props.isUpdatingResourceType
                                          }}
                                          SelectDisplayProps={{
                                            "style": {fontSize: 'small'}
                                          }}
                                         >
                                           {types.map(type =>
                                             <MenuItem value={type} key={type} style={{fontSize: 'small'}}>
                                               <em>{type}</em>
                                             </MenuItem>
                                           )}
                                         </Select>
                                       </FormControl>
                                     )}
                                   />
                                   {errors.templates && errors.templates[i] && errors.templates[i].type
                                   && touched.templates && touched.templates[i] && touched.templates[i].type &&
                                     <FormLabel
                                       error
                                     >
                                       <Translate id={`resource_types.dialog.templates.type.${errors['templates'][i].type}`} />
                                     </FormLabel>}
                                </ItemGrid>
                                {values.templates[i].type === 'scheduled'
                                &&
                                <Fragment>
                                  <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                     <Field
                                       name={`templates.${i}.cron`}
                                       render={({field, form}) => (
                                         <CustomInput
                                          inputProps={{
                                            "name": field.name,
                                            "value": field.value,
                                            "onChange": form.handleChange,
                                            "onBlur": form.handleBlur,
                                            "disabled": this.props.isUpdatingResourceType,
                                            "style": {fontSize: 'small'},
                                            "required": true
                                          }}
                                          labelText={<Translate id="resource_types.dialog.templates.cron.label" />}
                                          formControlProps={{
                                             "className": classes.formControlWithInfo,
                                          }}
                                        />
                                       )}
                                     />
                                     {errors.templates && errors.templates[i] && errors.templates[i].cron
                                     && touched.templates && touched.templates[i] && touched.templates[i].cron &&
                                       <FormLabel
                                         error
                                       >
                                         <Translate id={`resource_types.dialog.templates.cron.${errors['templates'][i].cron}`} />
                                       </FormLabel>}
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                     <Field
                                       name={`templates.${i}.timeZone`}
                                       render={({field, form}) => (
                                         <FormControl fullWidth>
                                           <InputLabel><Translate id="resource_types.dialog.templates.timeZone.label" /></InputLabel>
                                           <Select
                                            inputProps={{
                                              "name": field.name,
                                              "value": field.value,
                                              "onChange": form.handleChange,
                                              "disabled": this.props.isUpdatingResourceType
                                            }}
                                            SelectDisplayProps={{
                                              "style": {fontSize: 'small'}
                                            }}
                                           >
                                             {moment.tz.names().map(name =>
                                               <MenuItem value={name} key={name} style={{fontSize: 'small'}}>
                                                 <em>{name}</em>
                                               </MenuItem>
                                             )}
                                           </Select>
                                         </FormControl>
                                       )}
                                     />
                                     {errors.templates && errors.templates[i] && errors.templates[i].timeZone
                                     && touched.templates && touched.templates[i] && touched.templates[i].timeZone &&
                                       <FormLabel
                                         error
                                       >
                                         <Translate id={`resource_types.dialog.templates.timeZone.${errors['templates'][i].timeZone}`} />
                                       </FormLabel>}
                                  </ItemGrid>
                                </Fragment>}
                                {values.templates[i].type === 'periodic'
                                &&
                                  <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                     <Field
                                       name={`templates.${i}.frequency`}
                                       render={({field, form}) => (
                                         <CustomInput
                                          inputProps={{
                                            "name": field.name,
                                            "value": field.value,
                                            "onChange": form.handleChange,
                                            "onBlur": form.handleBlur,
                                            "disabled": this.props.isUpdatingResourceType,
                                            "style": {fontSize: 'small'},
                                            "type": "number"
                                          }}
                                          labelText={<Translate id="resource_types.dialog.templates.frequency.label" />}
                                          formControlProps={{
                                             "className": classes.formControlWithInfo
                                          }}
                                        />
                                       )}
                                     />
                                     {errors.templates && errors.templates[i] && errors.templates[i].frequency
                                     && touched.templates && touched.templates[i] && touched.templates[i].frequency &&
                                       <FormLabel
                                         error
                                       >
                                         <Translate id={`resource_types.dialog.templates.frequency.${errors['templates'][i].frequency}`} />
                                       </FormLabel>}
                                  </ItemGrid>}
                                  <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                    <Field
                                      name={`templates.${i}.pollingFixedRate`}
                                      render={({field, form}) => (
                                        <FormControlLabel
                                          classes={{
                                            label: classes.smallTex
                                          }}
                                          control={
                                            <Checkbox
                                              name={field.name}
                                              checked={field.value}
                                              color="primary"
                                              onChange={form.handleChange}
                                            />
                                          }
                                          label={<Translate id="resource_types.dialog.templates.pollingFixedRate.label" />}
                                        />)}
                                     />
                                     <Field
                                       name={`templates.${i}.resetObservations`}
                                       render={({field, form}) => (
                                         <FormControlLabel
                                           classes={{
                                             label: classes.smallTex
                                           }}
                                           control={
                                             <Checkbox
                                               name={field.name}
                                               checked={field.value}
                                               color="primary"
                                               onChange={form.handleChange}
                                             />
                                           }
                                           label={<Translate id="resource_types.dialog.templates.resetObservations.label" />}
                                         />)}
                                      />
                                      <Field
                                        name={`templates.${i}.parallel`}
                                        render={({field, form}) => (
                                          <FormControlLabel
                                            classes={{
                                              label: classes.smallTex
                                            }}
                                            control={
                                              <Checkbox
                                                name={field.name}
                                                checked={field.value}
                                                color="primary"
                                                onChange={form.handleChange}
                                              />
                                            }
                                            label={<Translate id="resource_types.dialog.templates.parallel.label" />}
                                          />)}
                                       />
                                  </ItemGrid>
                                </Grid>
                               </ExpansionPanelDetails>
                             </ExpansionPanel>
                            </div>)
                          )) : (
                            <div>
                              <Button
                                color="info"
                                style={{width: "100%"}}
                                onClick={() => arrayHelpers.push(emptyTemplateConfig)}
                              >
                                <Translate id="resource_types.dialog.addTemplate" />
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </FormControl>
                  </ItemGrid>
                </Fragment>}
                <DialogActions disableActionSpacing={false}>
                <Button
                  onClick={this.props.handleClose}
                >
                  <Translate id="resource_types.dialog.cancel" />
                </Button>
                {this.props.isProcessingResourceType
                  ? <div><CircularProgress /></div>
                  :  <Button
                        color="success"
                        type="submit"
                        disabled={!isValid}
                      >
                        <Translate id="resource_types.dialog.save" />
                    </Button>
                  }
                </DialogActions>
                </Grid>
                <FormLabel
                  error
                >
                  {this.props.createUserError}
                </FormLabel>
              </Form>
        </CustomDialog>
    );
  }
}

export default withStyles(customInputStyle)(ResourceTypeDialog);
