let tokenExpirationTimer = null;

const localStorageMiddleware = store => next => action => {

	const removeAccessToken = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('role');
		if(tokenExpirationTimer) {
			clearTimeout(tokenExpirationTimer);
		}
	}

	const setTokenExpirationTimer = (timeout) => {
		if(tokenExpirationTimer) {
			clearTimeout(tokenExpirationTimer);
		}
		tokenExpirationTimer = setTimeout(() => store.dispatch({ type: 'TOKEN_EXPIRED' }), timeout);
	}

	const getAccessToken = () => {
		const data = localStorage.getItem('token');
		if(!data) return null;
		const { expiration, token, role } = JSON.parse(data);

		if(expiration > Date.now()){
			setTokenExpirationTimer(expiration - Date.now());
			return {
				token,
				role
			};
		} else {
			removeAccessToken();
			return null;
		}
	}

	const saveAccessToken = (data) => {
		setTokenExpirationTimer(data.expiration - Date.now());
		localStorage.setItem('token',  JSON.stringify({
			token: data.token,
			expiration: data.expiration,
			role: data.role,
		}));
	}

	let result = null;

  switch(action.type) {
		case 'RESTORE_AUTH_STATE':
			result = next({...action, token: getAccessToken()});
			break;
		case 'SIGNIN_SUCCESS':
			saveAccessToken(action.res);
			result = next(action);
			break;
    case 'SIGNOUT_REQUEST':
		case 'TOKEN_EXPIRED':
		case 'TOKEN_REMOVED':
		case 'TOKEN_INVALID':
			removeAccessToken();
		  result = next(action);
      break;

		default:
		result = next(action);
	}
  return result;
}

export default localStorageMiddleware;
