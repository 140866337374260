import LoginPage from "../views/Public/Login.jsx";
import ForgotPasswordPage from "../views/Public/ForgotPassword.jsx";
import SetPasswordPage from "../views/Public/SetPassword.jsx";

const loginRoutes = [
  {
    path: "/login",
    component: LoginPage,
    navbarName: "Login",
  },
  {
    path: "/forgot_password",
    component: ForgotPasswordPage,
    navbarName: "Forgot Password",
  },
  {
    path: "/set_password",
    component: SetPasswordPage,
    navbarName: "Set Password",
  },
  { redirect: true, path: "/", to: "/login", navbarName: "Redirect" }
];

export default loginRoutes;
