import SuperAdminDashboardPage from "../views/Private/SuperAdminDashboard.jsx";
import AdminDashboardPage from "../views/Private/AdminDashboard.jsx";
import EditorDashboardPage from "../views/Private/EditorDashboard.jsx";
import ViewerDashboardPage from "../views/Private/ViewerDashboard.jsx";
import CustomersPage from "../views/Private/Customers.jsx";
import UsersPage from "../views/Private/Users.jsx";
import ResourcesPage from "../views/Private/Resources.jsx";
import AlarmsPage from "../views/Private/Alarms.jsx";
import ResourceTypesPage from "../views/Private/ResourceTypes.jsx";
import SettingsPage from "../views/Private/Settings.jsx";

import {
  Dashboard,
  Group,
  Domain,
  Cloud,
  ChevronRight,
  Alarm,
  PhoneIphone,
  Settings,
} from "@material-ui/icons";

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: SuperAdminDashboardPage,
    accessLevel: [3],
  },
  {
    path: "/dashboard",
    sidebarName: "dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: AdminDashboardPage,
    accessLevel: [2],
  },
  {
    path: "/dashboard",
    sidebarName: "dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: EditorDashboardPage,
    accessLevel: [1],
  },
  {
    path: "/dashboard",
    sidebarName: "dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: ViewerDashboardPage,
    accessLevel: [0],
  },
  {
    path: "/customers",
    sidebarName: "customers",
    icon: Domain,
    component: CustomersPage,
    accessLevel: [3],
  },
  {
    path: "/resourcetypes",
    sidebarName: "resource_types",
    icon: PhoneIphone,
    component: ResourceTypesPage,
    accessLevel: [3],
  },
  {
    path: "/users",
    sidebarName: "users",
    icon: Group,
    component: UsersPage,
    accessLevel: [2, 3],
  },
  {
    path: "/resources",
    sidebarName: "resources",
    icon: Cloud,
    component: ResourcesPage,
    nested: [],
    nestedIcon: ChevronRight,
    accessLevel: [0, 1, 2, 3],
  },
  {
    path: "/alarms",
    sidebarName: "alarms",
    icon: Alarm,
    component: AlarmsPage,
    accessLevel: [2, 3],
  },
  {
    path: "/settings",
    sidebarName: "settings",
    icon: Settings,
    component: SettingsPage,
    accessLevel: [3],
  },
  {
    redirect: true,
    path: "/",
    to: "/dashboard",
    navbarName: "Redirect",
    accessLevel: [0, 1, 2, 3],
  }
];

export default dashboardRoutes;
