import React from "react";
import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { SelectLanguage } from "../";
import { withLocalize } from "react-localize-redux";
import {
  withStyles,
  IconButton,
  MenuItem,
  MenuList,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  Hidden,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";

import headerLinksStyle from "../../assets/jss/material-dashboard-react/headerLinksStyle";

class HeaderLinks extends React.Component {
  state = {
    open: false
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  _signout = () => {
    this.props.signout();
    this.setState({ open: false });
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div>
          <IconButton
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color="inherit"
            aria-label="Person"
            onClick={this.handleToggle}
            className={classes.buttonLink}
          >
            <Person className={classes.links} />
            <Hidden mdUp>
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </IconButton>
          <Popper
            anchorEl={this.anchorEl}
            transition
            open={open}
            placement="bottom"
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperResponsive
            }
          >
          {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      {this.props.localizationEnabled
                      ? <MenuItem
                          className={classes.dropdownItemWithSelect}
                         >
                          <SelectLanguage
                            languages={this.props.languages}
                            setActiveLanguage={this.props.setActiveLanguage}
                          />
                        </MenuItem>
                      :null}
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={this.props.openProfile}
                      >
                        <Translate id="header.profile" />
                      </MenuItem>
                      <MenuItem
                        onClick={this._signout}
                        className={classes.dropdownItem}
                      >
                        <Translate id="header.signout" />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      </div>
    );
  }
}

export default withLocalize(withStyles(headerLinksStyle)(HeaderLinks));
