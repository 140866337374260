// ##############################
// // // Table styles
// #############################

import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont
} from "../material-dashboard-react.jsx";

const tableStyle = theme => ({
  paper: {
    overflowX: "auto"
  },
  warningTableHeader: {
    color: warningColor
  },
  primaryTableHeader: {
    color: primaryColor
  },
  dangerTableHeader: {
    color: dangerColor
  },
  successTableHeader: {
    color: successColor
  },
  infoTableHeader: {
    color: infoColor
  },
  roseTableHeader: {
    color: roseColor
  },
  grayTableHeader: {
    color: grayColor
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    tableLayout: "fixed",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "0.75em",
    textAlign: "left",
    fontWeight: "bold",
    paddingLeft: "8px",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  tableHeadActionsCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "0.75em",
    textAlign: "center",
    fontWeight: "bold",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  tableCell: {
    ...defaultFont,
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineHeight: "1.42857143",
    padding: "6px 8px",
    verticalAlign: "middle",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  tableRowHead: {
    height: "36px"
  },
  tableRow: {
    height: "36px",
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    }
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableActionButton: {
    flexDirection: "column",
    width: "27px",
    height: "27px"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  tableActions: {
    textAlign: "center",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor,
    boxShadow: "none"
  },
  notifications: {
    backgroundColor: "transparent",
    color: dangerColor,
    boxShadow: "none"
  },
  sendCommand: {
    "&:focus,&:hover": {
      backgroundColor: grayColor,
      opacity: 0.8,
      boxShadow: "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    backgroundColor: grayColor,
    boxShadow: "none"
  },
  sendCommandSet: {
    "&:focus,&:hover": {
      backgroundColor: dangerColor,
      opacity: 0.8,
      boxShadow: "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    backgroundColor: dangerColor,
    boxShadow: "none"
  },
  sendCommandSent: {
    "&:focus,&:hover": {
      backgroundColor: successColor,
      opacity: 0.8,
      boxShadow: "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
    },
    backgroundColor: successColor,
    boxShadow: "none"
  },
  whiteText: {
    color: "#FFFFFF"
  },
  messages: {
    backgroundColor: "transparent",
    color: infoColor,
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor,
    boxShadow: "none"
  },
  signinAs: {
    backgroundColor: "transparent",
    color: grayColor,
    boxShadow: "none"
  },
  tooltip: {
    padding: "10px 15px",
    minWidth: "130px",
    color: "#555555",
    lineHeight: "1.7em",
    background: "#FFFFFF",
    border: "none",
    borderRadius: "3px",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: "200px",
    textAlign: "center",
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    overflowWrap: "break-word",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto"
  }
});

export default tableStyle;
