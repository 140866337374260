import React from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import cx from "classnames";
import { Translate } from "react-localize-redux";
import {
  withStyles,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import { HeaderLinks } from "../";

import sidebarStyle from "../../assets/jss/material-dashboard-react/sidebarStyle.jsx";
import { version as versionNumber } from "../../../package.json"

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) return null;
        const whiteFontClasses = cx({
          [" " + classes.whiteFont]: activeRoute(prop.path)
        });
        return !prop.nested
        ? (
          <NavLink
              to={prop.path}
              className={classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink}>
                <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={<Translate id={`sidebar.${prop.sidebarName}`} />}
                  className={classes.itemText + whiteFontClasses}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
        ) : (<div
              key={key}
              >
          <NavLink
              exact
              to={prop.path}
              className={classes.item}
              activeClassName="active"
            >
              <ListItem button className={classes.itemLink}>
                <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={<Translate id={`sidebar.${prop.sidebarName}`} />}
                  className={classes.itemText + whiteFontClasses}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
            {prop.nested.map((nested, k) =>
              <NavLink
                  exact
                  to={nested.path}
                  className={classes.item}
                  style={{marginLeft: '0.1rem'}}
                  activeClassName="active"
                  key={k}
                >
                  <ListItem button className={classes.itemLinkNested}>
                    <ListItemIcon className={classes.nestedItemIcon}>
                      <nested.icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={nested.sidebarName}
                      className={classes.nestedItemText}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
            )}
        </div>);
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <Link to="/" className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </Link>
    </div>
  );
  const version = <div className={classes.version}>
      {`v${versionNumber}`}
    </div>;
  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks
              signout={props.signout}
            />
            {links}
            {version}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
          {version}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
