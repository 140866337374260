import api from '../api/admin';

export const saveKeys = data => dispatch => {
  dispatch({ type: 'SAVE_KEYS_REQUEST' });
  api.saveKeys(data)
      .then(res => {
          dispatch({ type: 'SAVE_KEYS_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'SAVE_KEYS_FAILURE', err: err.response });
      })
};

export const createUser = (data, cb) => dispatch => {
  dispatch({ type: 'CREATE_USER_REQUEST' });
  api.createUser(data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'CREATE_USER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'CREATE_USER_FAILURE', err: err.response });
      })
};

export const createUserWithoutConfirmation = (data, cb) => dispatch => {
  dispatch({ type: 'CREATE_USER_WITHOUT_CONFIRMATION_REQUEST' });
  api.createUserWithoutConfirmation(data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'CREATE_USER_WITHOUT_CONFIRMATION_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'CREATE_USER_WITHOUT_CONFIRMATION_FAILURE', err: err.response });
      })
};

export const getUsers = () => dispatch => {
  dispatch({ type: 'GET_USERS_REQUEST' });
  api.getUsers()
      .then(res => {
          dispatch({ type: 'GET_USERS_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_USERS_FAILURE', err: err.response });
      })
};

export const deleteUser = userId => dispatch => {
  dispatch({ type: 'DELETE_USER_REQUEST' });
  api.deleteUser(userId)
      .then(res => {
          dispatch({ type: 'DELETE_USER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'DELETE_USER_FAILURE', err: err.response });
      })
};

export const getUser = (userId, cb) => dispatch => {
  dispatch({ type: 'GET_USER_REQUEST' });
  api.getUser(userId)
      .then(res => {
          cb && cb(res.data);
          dispatch({ type: 'GET_USER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_USER_FAILURE', err: err.response });
      })
};

export const updateUser = (userId, data, cb) => dispatch => {
  dispatch({ type: 'UPDATE_USER_REQUEST' });
  api.updateUser(userId, data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'UPDATE_USER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'UPDATE_USER_FAILURE', err: err.response });
      })
};

export const createCustomer = (data, cb) => dispatch => {
  dispatch({ type: 'CREATE_CUSTOMER_REQUEST' });
  api.createCustomer(data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'CREATE_CUSTOMER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'CREATE_CUSTOMER_FAILURE', err: err.response });
      })
};

export const getCustomers = () => dispatch => {
  dispatch({ type: 'GET_CUSTOMERS_REQUEST' });
  api.getCustomers()
      .then(res => {
          dispatch({ type: 'GET_CUSTOMERS_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_CUSTOMERS_FAILURE', err: err.response });
      })
};

export const deleteCustomer = customerId => dispatch => {
  dispatch({ type: 'DELETE_CUSTOMER_REQUEST' });
  api.deleteCustomer(customerId)
      .then(res => {
          dispatch({ type: 'DELETE_CUSTOMER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'DELETE_CUSTOMER_FAILURE', err: err.response });
      })
};

export const getCustomer = (customerId, cb) => dispatch => {
  dispatch({ type: 'GET_CUSTOMER_REQUEST' });
  api.getCustomer(customerId)
      .then(res => {
          cb && cb(res.data);
          dispatch({ type: 'GET_CUSTOMER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_CUSTOMER_FAILURE', err: err.response });
      })
};

export const updateCustomer = (customerId, data, cb) => dispatch => {
  dispatch({ type: 'UPDATE_CUSTOMER_REQUEST' });
  api.updateCustomer(customerId, data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'UPDATE_CUSTOMER_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'UPDATE_CUSTOMER_FAILURE', err: err.response });
      })
};

export const getResourceTypes = () => dispatch => {
  dispatch({ type: 'GET_RESOURCE_TYPES_REQUEST' });
  api.getResourceTypes()
      .then(res => {
          dispatch({ type: 'GET_RESOURCE_TYPES_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_RESOURCE_TYPES_FAILURE', err: err.response });
      })
};

export const getResourceType = (resourceTypeId, cb) => dispatch => {
  dispatch({ type: 'GET_RESOURCE_TYPE_REQUEST' });
  api.getResourceType(resourceTypeId)
      .then(res => {
          cb && cb(res.data);
          dispatch({ type: 'GET_RESOURCE_TYPE_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'GET_RESOURCE_TYPE_FAILURE', err: err.response });
      })
};

export const createResourceType = (data, cb) => dispatch => {
  dispatch({ type: 'CREATE_RESOURCE_TYPE_REQUEST' });
  api.createResourceType(data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'CREATE_RESOURCE_TYPE_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'CREATE_RESOURCE_TYPE_FAILURE', err: err.response });
      })
};

export const updateResourceType = (resourceTypeId, data, cb) => dispatch => {
  dispatch({ type: 'UPDATE_RESOURCE_TYPE_REQUEST' });
  api.updateResourceType(resourceTypeId, data)
      .then(res => {
          cb && cb();
          dispatch({ type: 'UPDATE_RESOURCE_TYPE_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'UPDATE_RESOURCE_TYPE_FAILURE', err: err.response });
      })
};

export const signinAs = userId => dispatch => {
  dispatch({ type: 'SIGNIN_AS_REQUEST' });
  api.signinAs(userId)
      .then(res => {
          dispatch({ type: 'SIGNOUT_REQUEST' });
          dispatch({ type: 'SIGNIN_SUCCESS', res: res.data });
      })
      .catch(err => {
          dispatch({ type: 'SIGNIN_AS_FAILURE', err: err.response });
      })
}

export const backupDB = () => async (dispatch) => {
  const result = await api.backupDB();
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = window.URL.createObjectURL(new Blob([result.data]));
  link.setAttribute('type', 'hidden');
  link.setAttribute('download', 'backup.tar');
  link.click();
  URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
}

export const restoreDB = (backupFile) => async (dispatch) => {
  dispatch({ type: 'RESTORE_DB_REQUEST' });
  api.restoreDB(backupFile)
      .then(res => {
          dispatch({ type: 'RESTORE_DB_SUCCESS' });
      })
      .catch(err => {
          dispatch({ type: 'RESTORE_DB_FAILURE', err: err.response });
      });
}
