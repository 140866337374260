import { getTranslate } from "react-localize-redux";

const snackbarsConfig = [
	{
		type: 'UPDATE_PROFILE_SUCCESS',
		color: 'success',
	},
	{
		type: 'UPDATE_PROFILE_FAILURE',
		color: 'danger',
	},
	{
		type: 'UPDATE_RESOURCE_SUCCESS',
		color: 'success',
	},
	{
		type: 'UPDATE_RESOURCE_FAILURE',
		color: 'danger',
	},
	// {
	// 	type: 'GET_RESOURCES_SUCCESS',
	// 	color: 'success',
	// },
	{
		type: 'GET_RESOURCES_FAILURE',
		color: 'danger',
	},
	// {
	// 	type: 'GET_USERS_SUCCESS',
	// 	color: 'success',
	// },
	{
		type: 'GET_USERS_FAILURE',
		color: 'danger',
	},
	// {
	// 	type: 'GET_CUSTOMERS_SUCCESS',
	// 	color: 'success',
	// },
	{
		type: 'GET_CUSTOMERS_FAILURE',
		color: 'danger',
	},
	{
		type: 'CREATE_USER_SUCCESS',
		color: 'success',
	},
	{
		type: 'CREATE_USER_FAILURE',
		color: 'danger',
	},
	{
		type: 'UPDATE_USER_SUCCESS',
		color: 'success',
	},
	{
		type: 'UPDATE_USER_FAILURE',
		color: 'danger',
	},
	{
		type: 'DELETE_USER_SUCCESS',
		color: 'success',
	},
	{
		type: 'DELETE_USER_FAILURE',
		color: 'danger',
	},
	{
		type: 'CREATE_CUSTOMER_SUCCESS',
		color: 'success',
	},
	{
		type: 'CREATE_CUSTOMER_FAILURE',
		color: 'danger',
	},
	{
		type: 'UPDATE_CUSTOMER_SUCCESS',
		color: 'success',
	},
	{
		type: 'UPDATE_CUSTOMER_FAILURE',
		color: 'danger',
	},
	{
		type: 'DELETE_CUSTOMER_SUCCESS',
		color: 'success',
	},
	{
		type: 'DELETE_CUSTOMER_FAILURE',
		color: 'danger',
	},
	{
		type: 'DELETE_ALARM_SUCCESS',
		color: 'success',
	},
	{
		type: 'DELETE_ALARM_FAILURE',
		color: 'danger',
	},
	{
		type: 'CREATE_RESOURCE_TYPE_SUCCESS',
		color: 'success',
	},
	{
		type: 'CREATE_RESOURCE_TYPE_FAILURE',
		color: 'danger',
	},
	{
		type: 'UPDATE_RESOURCE_TYPE_SUCCESS',
		color: 'success',
	},
	{
		type: 'UPDATE_RESOURCE_TYPE_FAILURE',
		color: 'danger',
	},
	{
		type: 'DELETE_RESOURCE_TYPE_SUCCESS',
		color: 'success',
	},
	{
		type: 'DELETE_RESOURCE_TYPE_FAILURE',
		color: 'danger',
	},
	{
		type: 'UPDATE_ALARM_SUCCESS',
		color: 'success',
	},
	{
		type: 'UPDATE_ALARM_FAILURE',
		color: 'danger',
	},
	{
		type: 'CREATE_USER_WITHOUT_CONFIRMATION_SUCCESS',
		color: 'success',
	},
	{
		type: 'CREATE_USER_WITHOUT_CONFIRMATION_FAILURE',
		color: 'danger',
	},
	{
		type: 'RESTORE_DB_SUCCESS',
		color: 'success',
	},
	{
		type: 'RESTORE_DB_FAILURE',
		color: 'danger',
	},
];

const createSnackbars = store => next => action => {
	let result = null;

	const showSnackbar = (text, color) => {
		const id = store.getState().user.snackbars.length ? store.getState().user.snackbars.slice(-1)[0].id + 1 : 1;
		const timerId = setTimeout(() => store.dispatch({type: 'CLOSE_SNACKBAR_REQUEST', id}), 3000);
		store.dispatch({type: 'CREATE_SNACKBAR_REQUEST', id, timerId, data: {text, color}});
	}

	const snackBar = snackbarsConfig.find(item => item.type === action.type);

	if(snackBar) {
		const translate = getTranslate(store.getState().localize);
		showSnackbar(translate(`snackbars.${snackBar.type}`), snackBar.color);
		result = next(action);
	} else {
		switch(action.type) {
			case 'CLOSE_SNACKBAR_REQUEST':
				if(store.getState().user.snackbars.find(snackbar => snackbar.id === action.id)) clearTimeout(store.getState().user.snackbars.find(snackbar => snackbar.id === action.id).timerId);
				result = next(action);
				break;

			default:
			result = next(action);
		}
	}

  return result;
}

export default createSnackbars;
