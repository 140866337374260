import React from 'react';

const SelectLanguage = ({
  languages,
  setActiveLanguage
}) =>
  (<select
    style={{cursor: "pointer"}}
    onChange={(e) => {
      setActiveLanguage(e.target.value);
      localStorage.setItem('language', e.target.value);
    }}
    value={localStorage.getItem('language')}
  >
    {languages.map((language, i) =>
      <option
        key={i}
        value={language.code}
      >
        {language.name}
      </option>
    )}
  </select>);

export default SelectLanguage;
