import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class CustomDialog extends React.Component {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={this.props.maxWidth || 'xs'}
          classes={this.props.classes}
        >
          <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent style={this.props.contentStyle}>
            {this.props.children}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
