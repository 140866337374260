import React, { Component }  from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid } from "@material-ui/core";
import { getTranslate } from "react-localize-redux";

import {
  RegularCard,
  ItemGrid,
  CustomPaginationTable,
} from "../../components";

import {
  deleteAlarm,
  updateAlarm,
} from "../../actions/user";

const alarmsTableParameters = [
  {
    roles: [0, 1, 2, 3],
    columnData: [
      { id: 'resource', numeric: false, disablePadding: true, label: 'resource' },
      { id: 'type', numeric: false, disablePadding: true, label: 'type' },
      { id: 'text', numeric: false, disablePadding: true, label: 'text' },
      { id: 'raised', numeric: false, disablePadding: true, label: 'raised' },
      { id: 'last', numeric: false, disablePadding: true, label: 'last' },
      { id: 'occured', numeric: false, disablePadding: true, width: "60%", label: 'occured' },
      { id: 'status', numeric: false, disablePadding: true, width: "60%", label: 'status' },
    ]
  },
];


class Alarms extends Component {
  _deleteAlarm = id => {
    this.props.deleteAlarm(id);
  }

  _updateAlarm = (id, severity, status) => {
    this.props.updateAlarm(id, { severity, status });
  }

  render() {
    const { translate } = this.props;
    const tableParams = alarmsTableParameters.find(parameters => parameters.roles.find(role => role === this.props.role));
    return (
      <div>
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={translate("alarms.title")}
              headerColor="red"
              content={
                <CustomPaginationTable
                  deleteItem={this._deleteAlarm}
                  updateAlarm={this._updateAlarm}
                  role={this.props.role}
                  deleteButton
                  data={this.props.alarms.map(alarm => ({
                    ...alarm,
                    resource: alarm.source.id,
                    occured: alarm.history.length,
                    raised: alarm.history[0].timestamp,
                    last: alarm.history[alarm.history.length - 1].timestamp,
                  }))}
                  columnData={tableParams.columnData}
                />
              }
            />
          </ItemGrid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize),
  alarms: state.user.alarms,
  role: state.session.role,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  deleteAlarm,
  updateAlarm,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
