import React, { Component }  from "react";
import { Formik, Field, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { Grid, CircularProgress, FormControl, FormLabel, DialogActions } from "@material-ui/core";
import { Translate } from "react-localize-redux";

import {
  Button,
  CustomInput,
  ItemGrid,
  CustomDialog,
} from "../../components";

const CustomerFormVS = yupObject().shape({
  customer: yupString()
    .required('required'),
  name: yupString()
    .required('required'),
  description: yupString()
    .required('required'),

});

const CustomerDialog = ({
  customer,
  isOpen,
  resources,
  createCustomer,
  updateCustomer,
  isCreatingCustomer,
  isUpdatingCustomer,
  createCustomerError,
  enableReinitialize,
  handleClose,
  edit,
  title,
  isUpdatingUser,
}) =>
  <Formik
  	initialValues={customer}
  	validationSchema={CustomerFormVS}
    isInitialValid={props => {
      CustomerFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={(values, { props }) => {
    		const data = {
    			customer: values.customer,
    			name: values.name,
          description: values.description,
    		};
    		edit ? updateCustomer(customer.id, data, () => handleClose()) : createCustomer(data, () => handleClose());
    	}
    }
    render={props => <CustomersForm {...props}
      customer={customer}
      isOpen={isOpen}
      resources={resources}
      handleClose={handleClose}
      isCreatingCustomer={isCreatingCustomer}
      isUpdatingCustomer={isUpdatingCustomer}
      createCustomerError={createCustomerError}
      title={title}
      edit={edit}
      isUpdatingUser={isUpdatingUser}
    />}
  />

class CustomersForm extends Component {
  render() {
    const {
      errors,
      touched,
      isValid,
    } = this.props;
    return (
        <CustomDialog
          title={this.props.title}
          handleClose={this.props.handleClose}
          open={this.props.isOpen}
        >
            <Form className="form">
              <Grid container justify="flex-end">
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="customer"
                    placeholder={<Translate id="customers.dialog.customer" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="customers.dialog.customer" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isCreatingCustomer
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.customer && touched.customer &&
                    <FormLabel
                      error
                    >
                      <Translate id={`customers.dialog.errors.customer.${errors.customer}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="name"
                    placeholder={<Translate id="customers.dialog.name" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="customers.dialog.name" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isCreatingCustomer
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.name && touched.name &&
                    <FormLabel
                      error
                    >
                      <Translate id={`customers.dialog.errors.name.${errors.name}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="description"
                    placeholder={<Translate id="customers.dialog.description" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="customers.dialog.description" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isCreatingCustomer
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.description && touched.description &&
                    <FormLabel
                      error
                    >
                      <Translate id={`customers.dialog.errors.description.${errors.description}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <DialogActions>
                  <Button
                    onClick={this.props.handleClose}
                  >
                    Cancel
                  </Button>
                  {(this.props.edit ? this.props.isUpdatingCustomer : this.props.isCreatingCustomer)
                    ? <div><CircularProgress /></div>
                    :  <Button
                          color="success"
                          type="submit"
                          disabled={!isValid}
                        >
                          <Translate id="customers.dialog.save" />
                      </Button>
                    }
                </DialogActions>
                </Grid>
                <FormLabel
                  error
                >
                  {this.props.createCustomerError}
                </FormLabel>
              </Form>
        </CustomDialog>
    );
  }
}

export default CustomerDialog;
