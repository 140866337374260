import React, { Component, Fragment }  from "react";
import { Formik, Field, Form, FieldArray } from 'formik';
import {object as yupObject, array as yupArray, boolean as yupBoolean, string as yupString, number as yupNumber } from 'yup';
import { Translate } from "react-localize-redux";
import {
  withStyles,
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  FormGroup,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  MenuItem,
  Select,
  Checkbox,
  Tooltip,
  Button as CoreButton,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import FilterNone from '@material-ui/icons/FilterNone';


import customInputStyle from "../../assets/jss/material-dashboard-react/customInputStyle";

import {
  Button,
  CustomInput,
  ItemGrid,
  CustomDialog,
  Information,
} from "../../components";

const AlertsFormVS = yupObject().shape({
  alerts: yupArray().of(yupObject().shape({
    metric: yupString()
    .required('required'),
    useRange: yupBoolean()
    .required('required'),
    below: yupNumber()
    .required('required'),
    above: yupNumber()
    .required('required'),
    days: yupArray().of(yupNumber()),
    time: yupObject().shape({
      from: yupNumber().min(0).max(24).required(),
      to: yupNumber().min(0).max(24).required(),
    }),
    from_email: yupString()
    .email('invalid')
    .required('required'),
    to_email_adresses: yupArray().of(yupString()
    .email('invalid email')
    .required('required')),
    subject: yupString()
    .required('required'),
    message: yupString()
    .required('required'),
    frequency: yupNumber()
    .required('required')
    .min(5),
  }))
});

const valuesMap = ['metric', 'useRange', 'below', 'above', 'days', 'time', 'from_email','to_email_adresses', 'subject', 'message', 'frequency'];
const emptyAlertConfig = {
  metric: '',
  useRange: false,
  below: 0,
  above: 0,
  days: [1, 2, 3, 4, 5, 6, 7],
  time: {
    from: 0,
    to: 23
  },
  from_email: 'sensor@italks.eu',
  to_email_adresses: [''],
  subject: '',
  message: '',
  frequency: 5
};

const DaysSelector = withStyles({
    root: {
      paddingBottom: "0.1rem",
      paddingTop: "0.1rem"
    },
    label: {
      fontSize: "small"
    }
})(({
  name,
  days,
  onDayChange,
  disabled,
  classes
}) => (
        <FormGroup row style={{justifyContent: "space-between"}}>
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            style={{margin: "0", flexDirection: "column"}}
            control={
              <Checkbox
                classes={{
                  root: classes.root
                }}
                checked={!!days.find(value => value === 2)}
                value="2"
                color="primary"
                onChange={(e) => onDayChange(e.target.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={<Translate id="resources.dialog.setup_alerts.days.Mon" />}
          />
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            style={{marginRight: "0", flexDirection: "column"}}
            control={
              <Checkbox
                classes={{
                  root: classes.root
                }}
                checked={!!days.find(value => value === 3)}
                value="3"
                color="primary"
                onChange={(e) => onDayChange(e.target.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={<Translate id="resources.dialog.setup_alerts.days.Tue" />}
          />
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            style={{marginRight: "0", flexDirection: "column"}}
            control={
              <Checkbox
                classes={{
                  root: classes.root
                }}
                checked={!!days.find(value => value === 4)}
                value="4"
                color="primary"
                onChange={(e) => onDayChange(e.target.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={<Translate id="resources.dialog.setup_alerts.days.Wed" />}
          />
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            style={{marginRight: "0", flexDirection: "column"}}
            control={
              <Checkbox
                classes={{
                  root: classes.root
                }}
                checked={!!days.find(value => value === 5)}
                color="primary"
                value="5"
                onChange={(e) => onDayChange(e.target.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={<Translate id="resources.dialog.setup_alerts.days.Thu" />}
          />
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            style={{marginRight: "0", flexDirection: "column"}}
            control={
              <Checkbox
                classes={{
                  root: classes.root
                }}
                checked={!!days.find(value => value === 6)}
                value="6"
                color="primary"
                onChange={(e) => onDayChange(e.target.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={<Translate id="resources.dialog.setup_alerts.days.Fri" />}
          />
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            style={{marginRight: "0", flexDirection: "column"}}
            control={
              <Checkbox
                classes={{
                  root: classes.root
                }}
                checked={!!days.find(value => value === 7)}
                value="7"
                onChange={(e) => onDayChange(e.target.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={<Translate id="resources.dialog.setup_alerts.days.Sat" />}
          />
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            style={{marginRight: "0", flexDirection: "column"}}
            control={
              <Checkbox
                classes={{
                  root: classes.root
                }}
                checked={!!days.find(value => value === 1)}
                value="1"
                onChange={(e) => onDayChange(e.target.value, e.target.checked)}
                disabled={disabled}
              />
            }
            label={<Translate id="resources.dialog.setup_alerts.days.Sun" />}
          />
          <Information
            text={<Translate id="resources.dialog.setup_alerts.days.tooltip" />}
            iconStyle={{alignSelf: "center"}}
          />
        </FormGroup>
    ));

const SetupAlertsDialog = ({
  resource,
  isOpen,
  updateResource,
  isUpdatingResource,
  enableReinitialize,
  updateResourceError,
  handleClose,
  title,
  classes,
}) =>
  <Formik
  	initialValues={{alerts: Object.keys(resource)
      .reduce((acc, field) => field.match(/^alert_email_\d{1,}$/)
      ? [...acc, resource[field].reduce((acc, item, i) => {
          if(valuesMap[i] === 'to_email_adresses') return {...acc, [valuesMap[i]]: item.split(';')};
          if(valuesMap[i] === 'below') return {...acc, [valuesMap[i]]: parseFloat(item)};
          if(valuesMap[i] === 'above') return {...acc, [valuesMap[i]]: parseFloat(item)};
          if(valuesMap[i] === 'time') return {...acc, [valuesMap[i]]: { from: parseFloat(item.split(',')[0]), to: parseFloat(item.split(',')[1])}};
          if(valuesMap[i] === 'days') return {...acc, [valuesMap[i]]: item.split(',').map(day => parseInt(day, 10))};
          if(valuesMap[i] === 'frequency') return {...acc, [valuesMap[i]]: item / 60}; // seconds to minutes
          return {...acc, [valuesMap[i]]: item};
        }
        , {})]
      : acc, [])}}
  	validationSchema={AlertsFormVS}
    isInitialValid={props => {
      AlertsFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={(values, { props }) => {
        const newAlerts = values.alerts.reduce((acc, alert, i) => ({...acc, [`alert_email_${i + 1}`]: valuesMap.map(value => {
          if(value === 'to_email_adresses') return alert[value].join(';');
          if(value === 'time') return [alert[value].from, alert[value].to].join(',');
          if(value === 'days') return alert[value].sort().join(',');
          if(value === 'frequency') return alert[value] * 60; // minutes to seconds
          return alert[value];
        })}), {});
        const updatedResource = Object.keys(resource).reduce((acc, field) => (field.match(/^alert_email_\d{1,}$/) || field.match(/^resourceTasks$/))
          ? acc
          : {...acc, [field]: resource[field]}, newAlerts);
    		updateResource(resource.id, updatedResource, () => handleClose());
    	}
    }
    render={props => <AlertsForm {...props}
      classes={classes}
      isOpen={isOpen}
      handleClose={handleClose}
      isUpdatingResource={isUpdatingResource}
      updateResourceError={updateResourceError}
      title={title}
    />}
  />

class AlertsForm extends Component {
  _dayChange = (name, value, days, checked) => {
      if(checked) {
        this.props.setFieldValue(name, [...days, (Number)(value)]);
      } else {
        this.props.setFieldValue(name, days.filter(day => day !== (Number)(value)));
      }
  }

  render() {
    const {
      errors,
      touched,
      values,
      isValid,
      classes,
    } = this.props;
    return (
        <CustomDialog
          title={this.props.title}
          handleClose={this.props.handleClose}
          open={this.props.isOpen}
          maxWidth='sm'
        >
            <Form className="form">
              <Grid container justify="flex-end">
                  <ItemGrid xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <FieldArray
                      name="alerts"
                      render={arrayHelpers => (
                        <div>
                          {values.alerts && values.alerts.length > 0 ? (
                            values.alerts.map((alert, i) => (
                            <div key={i}>
                             <ExpansionPanel key={i}>
                               <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{height: "36px"}}>
                                 <div style={{display: "flex", alignItems: "center", width: "50%"}}>{`${alert.metric} (${alert.below}, ${alert.above})`}</div>
                                 <div style={{width: "50%", textAlign: "end"}}>
                                   <Tooltip
                                       id="tooltip-top"
                                       title={<Translate id="tooltips.alerts.remove" />}
                                       placement="top"
                                       PopperProps={{ style: { pointerEvents: 'none' } }}
                                    >
                                     <IconButton
                                       color="secondary"
                                       onClick={(e) => {
                                         e.stopPropagation();
                                         arrayHelpers.remove(i);
                                       }}
                                     >
                                        <Remove />
                                     </IconButton>
                                   </Tooltip>
                                   <Tooltip
                                       id="tooltip-top"
                                       title={<Translate id="tooltips.alerts.add" />}
                                       placement="top"
                                       PopperProps={{ style: { pointerEvents: 'none' } }}
                                    >
                                     <IconButton
                                       style={{color: "green"}}
                                       onClick={(e) => {
                                         e.stopPropagation();
                                         arrayHelpers.insert(i + 1, emptyAlertConfig);
                                       }}
                                     >
                                       <Add />
                                     </IconButton>
                                   </Tooltip>
                                   <Tooltip
                                       id="tooltip-top"
                                       title={<Translate id="tooltips.alerts.duplicate" />}
                                       placement="top"
                                       PopperProps={{ style: { pointerEvents: 'none' } }}
                                    >
                                     <IconButton
                                       onClick={(e) => {
                                         e.stopPropagation();
                                         arrayHelpers.insert(i + 1, {...values.alerts[i]});
                                       }}
                                     >
                                       <FilterNone />
                                     </IconButton>
                                   </Tooltip>
                                 </div>
                               </ExpansionPanelSummary>
                               <ExpansionPanelDetails>
                                <Grid container justify="flex-end">
                                 <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                    <Field
                                      name={`alerts.${i}.metric`}
                                      render={({field, form}) => (
                                        <CustomInput
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "onChange": form.handleChange,
                                           "onBlur": form.handleBlur,
                                           "disabled": this.props.isUpdatingResource,
                                           "style": {fontSize: 'small'}
                                         }}
                                         labelText={<Translate id="resources.dialog.setup_alerts.metric.label" />}
                                         formControlProps={{
                                            "className": classes.formControlWithInfo
                                         }}
                                       />
                                      )}
                                    />
                                    <Information text={<Translate id="resources.dialog.setup_alerts.metric.tooltip" />} />
                                    {errors.alerts && errors.alerts[i] && errors.alerts[i].metric
                                    && touched.alerts && touched.alerts[i] && touched.alerts[i].metric &&
                                      <FormLabel
                                        error
                                      >
                                        <Translate id={`resources.dialog.setup_alerts.errors.metric.${errors['alerts'][i].metric}`} />
                                      </FormLabel>}
                                 </ItemGrid>
                                 <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Field
                                      name={`alerts.${i}.below`}
                                      render={({field, form}) => (
                                        <CustomInput
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "type": "number",
                                           "onChange": form.handleChange,
                                           "onBlur": form.handleBlur,
                                           "disabled": this.props.isUpdatingResource,
                                           "style": {fontSize: 'small'}
                                         }}
                                         labelText={
                                           values.alerts[i].useRange
                                            ? <Translate id="resources.dialog.setup_alerts.from_range.label" />
                                            : <Translate id="resources.dialog.setup_alerts.below.label" />
                                         }
                                         formControlProps={{
                                           "className": classes.formControlWithInfo,
                                           "style": {width: '25%'}
                                         }}
                                       />
                                      )}
                                    />
                                    <Information text={
                                      values.alerts[i].useRange
                                      ? <Translate id="resources.dialog.setup_alerts.from_range.tooltip" />
                                      : <Translate id="resources.dialog.setup_alerts.below.tooltip" />} />
                                    {errors.alerts && errors.alerts[i] && errors.alerts[i].below
                                      && touched.alerts && touched.alerts[i] && touched.alerts[i].below &&
                                      <FormLabel
                                        error
                                      >
                                        {errors.alerts[i].below}
                                      </FormLabel>}
                                    <Field
                                      name={`alerts.${i}.above`}
                                      render={({field, form}) => (
                                        <CustomInput
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "type": "number",
                                           "onChange": form.handleChange,
                                           "onBlur": form.handleBlur,
                                           "disabled": this.props.isUpdatingResource,
                                           "style": {fontSize: 'small'}
                                         }}
                                         labelText={
                                           values.alerts[i].useRange
                                           ? <Translate id="resources.dialog.setup_alerts.till_range.label" />
                                           : <Translate id="resources.dialog.setup_alerts.above.label" />}
                                         formControlProps={{
                                           "className": classes.formControlWithInfo,
                                           "style": {width: '25%'}
                                         }}
                                       />
                                      )}
                                    />
                                    <Information text={
                                      values.alerts[i].useRange
                                      ? <Translate id="resources.dialog.setup_alerts.till_range.tooltip" />
                                      : <Translate id="resources.dialog.setup_alerts.above.tooltip" />} />
                                    {errors.alerts && errors.alerts[i] && errors.alerts[i].above
                                      && touched.alerts && touched.alerts[i] && touched.alerts[i].above &&
                                      <FormLabel
                                        error
                                      >
                                        {errors.alerts[i].above}
                                      </FormLabel>}
                                      <Field
                                        name={`alerts.${i}.useRange`}
                                        render={({field, form}) => (
                                          <FormControlLabel
                                            classes={{
                                              label: classes.smallTex
                                            }}
                                            control={
                                              <Checkbox
                                                name={field.name}
                                                checked={field.value}
                                                color="primary"
                                                onChange={form.handleChange}
                                              />
                                            }
                                            label={<Translate id="resources.dialog.setup_alerts.useRange.label" />}
                                          />)}
                                       />
                                       <Information text={<Translate id="resources.dialog.setup_alerts.useRange.tooltip" />} />
                                 </ItemGrid>
                                 <ItemGrid xs={12} sm={12} md={12} style={{marginBottom: '0.5rem'}}>
                                    <Field
                                      name={`alerts.${i}.days`}
                                      render={({field, form}) => (
                                        <DaysSelector
                                           days={field.value}
                                           onDayChange={(value, checked) => this._dayChange(field.name, value, field.value, checked)}
                                           disabled={this.props.isUpdatingResource}
                                       />
                                      )}
                                    />
                                    {errors[`alerts.${i}.days`] && touched[`alerts.${i}.days`] &&
                                      <FormLabel
                                        error
                                      >
                                        {errors.alerts[i].days}
                                      </FormLabel>}
                                 </ItemGrid>
                                 <ItemGrid xs={12} sm={12} md={12} style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                   <FormLabel style={{fontSize: 'small'}}><Translate id="resources.dialog.setup_alerts.time.label" /></FormLabel>
                                    <Field
                                      name={`alerts.${i}.time.from`}
                                      render={({field, form}) => (
                                        <FormControl>
                                        <InputLabel><Translate id="resources.dialog.setup_alerts.from" /></InputLabel>
                                        <Select
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "onChange": form.handleChange,
                                           "disabled": this.props.isCreatingUser
                                         }}
                                         SelectDisplayProps={{
                                           "style": {fontSize: 'small'}
                                         }}
                                        >
                                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(hour =>
                                            <MenuItem value={hour} key={hour} style={{padding: 0, fontSize: 'small'}}>
                                              <em>{hour}</em>
                                            </MenuItem>
                                          )}
                                        </Select>
                                        </FormControl>
                                      )}
                                    />
                                    <Field
                                      name={`alerts.${i}.time.to`}
                                      render={({field, form}) => (
                                        <FormControl>
                                        <InputLabel><Translate id="resources.dialog.setup_alerts.to" /></InputLabel>
                                        <Select
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "onChange": form.handleChange,
                                           "disabled": this.props.isCreatingUser
                                         }}
                                         SelectDisplayProps={{
                                           "style": {fontSize: 'small'}
                                         }}
                                        >
                                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(hour =>
                                            <MenuItem value={hour} key={hour} style={{padding: 0, fontSize: 'small'}}>
                                              <em>{hour}</em>
                                            </MenuItem>
                                          )}
                                        </Select>
                                        </FormControl>
                                      )}
                                    />
                                    <Information
                                      text={<Translate id="resources.dialog.setup_alerts.time.tooltip" />}
                                      iconStyle={{alignSelf: "center"}}
                                    />
                                    <Field
                                      name={`alerts.${i}.frequency`}
                                      render={({field, form}) => (
                                        <CustomInput
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "type": "number",
                                           "onChange": form.handleChange,
                                           "onBlur": form.handleBlur,
                                           "disabled": this.props.isUpdatingResource,
                                           "style": {fontSize: 'small'}
                                         }}
                                         labelText={<Translate id="resources.dialog.setup_alerts.frequency.label" />}
                                         formControlProps={{
                                           "className": classes.formControlWithInfo,
                                           "style": {width: '25%'}
                                         }}
                                       />
                                      )}
                                    />
                                    <Information text={<Translate id="resources.dialog.setup_alerts.frequency.tooltip" />} />
                                 </ItemGrid>
                                 {errors.alerts && errors.alerts[i] && errors.alerts[i].frequency &&
                                   <FormLabel
                                     error
                                   >
                                     {errors.alerts[i].frequency}
                                   </FormLabel>}
                                 <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                    <Field
                                      name={`alerts.${i}.from_email`}
                                      render={({field, form}) => (
                                        <CustomInput
                                         inputProps={{
                                           "readOnly": true,
                                           "name": field.name,
                                           "value": field.value,
                                           "onChange": form.handleChange,
                                           "onBlur": form.handleBlur,
                                           "disabled": this.props.isUpdatingResource,
                                           "style": {fontSize: 'small'}
                                         }}
                                         labelText={<Translate id="resources.dialog.setup_alerts.from_email.label" />}
                                         formControlProps={{
                                           "className": classes.formControlWithInfo
                                         }}
                                       />
                                      )}
                                    />
                                    <Information text={<Translate id="resources.dialog.setup_alerts.from_email.tooltip" />} />
                                    {errors.alerts && errors.alerts[i] && errors.alerts[i].from_email
                                      && touched.alerts && touched.alerts[i] && touched.alerts[i].from_email &&
                                      <FormLabel
                                        error
                                      >
                                        <Translate id={`resources.dialog.setup_alerts.errors.email.${errors.alerts[i].from_email}`} />
                                      </FormLabel>}
                                 </ItemGrid>
                                 <ItemGrid xs={12} sm={12} md={12}>
                                   <FieldArray
                                     name={`alerts.${i}.to_email_adresses`}
                                     render={arrayHelpers => (
                                         values.alerts[i].to_email_adresses && values.alerts[i].to_email_adresses.length > 0 ? (
                                           values.alerts[i].to_email_adresses.map((to_email, k) => (
                                             <div key={k} style={{display: 'flex'}}>
                                               <Field
                                                 name={`alerts.${i}.to_email_adresses.${k}`}
                                                 render={({field, form}) => (
                                                   <CustomInput
                                                    inputProps={{
                                                      "name": field.name,
                                                      "value": field.value,
                                                      "onChange": form.handleChange,
                                                      "onBlur": form.handleBlur,
                                                      "disabled": this.props.isUpdatingResource,
                                                      "style": {fontSize: 'small'}
                                                    }}
                                                    labelText={<Translate id="resources.dialog.setup_alerts.to_email.label" />}
                                                    formControlProps={{
                                                      "className": classes.formControlToEmail,
                                                    }}
                                                  />
                                                 )}
                                               />
                                               <CoreButton
                                                 color="secondary"
                                                 style={{minWidth: "27px", width: "27px", fontWeight: "bold"}}
                                                 onClick={(e) => {
                                                   arrayHelpers.remove(k);
                                                 }}
                                               >
                                                 -
                                               </CoreButton>
                                               <CoreButton
                                                 style={{minWidth: "27px", width: "27px", marginLeft: "2px", color: "green", fontWeight: "bold"}}
                                                 onClick={(e) => {
                                                   arrayHelpers.insert(k + 1, '');
                                                 }}
                                               >
                                                 +
                                               </CoreButton>
                                               <Information text={<Translate id="resources.dialog.setup_alerts.to_email.tooltip" />} />
                                               {errors.alerts && errors.alerts[i] && errors.alerts[i].to_email_adresses
                                                 && touched.alerts && touched.alerts[i] && touched.alerts[i].to_email_adresses &&
                                                 <FormLabel
                                                   error
                                                 >
                                                  <Translate id={`resources.dialog.setup_alerts.errors.email.${errors.alerts[i].to_email_adresses[k]}`} />
                                                 </FormLabel>}
                                            </div>
                                            ))
                                          ) : (
                                            <Fragment>
                                              <Button
                                                color="info"
                                                style={{width: "95%"}}
                                                onClick={() => arrayHelpers.push('')}
                                              >
                                                <Translate id="resources.dialog.setup_alerts.addEmail" />
                                              </Button>
                                              <Information text={<Translate id="resources.dialog.setup_alerts.to_email.tooltip" />} />
                                            </Fragment>
                                          ))}
                                      />
                                 </ItemGrid>
                                 <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                    <Field
                                      name={`alerts.${i}.subject`}
                                      render={({field, form}) => (
                                        <CustomInput
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "onChange": form.handleChange,
                                           "onBlur": form.handleBlur,
                                           "disabled": this.props.isUpdatingResource,
                                           "style": {fontSize: 'small'}
                                         }}
                                         labelText={<Translate id="resources.dialog.setup_alerts.subject.label" />}
                                         formControlProps={{
                                           "className": classes.formControlWithInfo
                                         }}
                                       />
                                      )}
                                    />
                                    <Information text={<Translate id="resources.dialog.setup_alerts.subject.tooltip" />} />
                                    {errors.alerts && errors.alerts[i] && errors.alerts[i].subject
                                      && touched.alerts && touched.alerts[i] && touched.alerts[i].subject &&
                                      <FormLabel
                                        error
                                      >
                                        <Translate id={`resources.dialog.setup_alerts.errors.subject.${errors.alerts[i].subject}`} />
                                      </FormLabel>}
                                 </ItemGrid>
                                 <ItemGrid xs={12} sm={12} md={12} style={{display: 'flex'}}>
                                    <Field
                                      name={`alerts.${i}.message`}
                                      render={({field, form}) => (
                                        <CustomInput
                                         inputProps={{
                                           "name": field.name,
                                           "value": field.value,
                                           "onChange": form.handleChange,
                                           "multiline": true,
                                           "rows": 3,
                                           "rowsMax": 6,
                                           "onBlur": form.handleBlur,
                                           "disabled": this.props.isUpdatingResource,
                                           "style": {fontSize: 'small'}
                                         }}
                                         labelText={<Translate id="resources.dialog.setup_alerts.message.label" />}
                                         formControlProps={{
                                           "className": classes.formControlWithInfo
                                         }}
                                       />
                                      )}
                                    />
                                    <Information text={<Translate id="resources.dialog.setup_alerts.message.tooltip" />} />
                                    {errors.alerts && errors.alerts[i] && errors.alerts[i].message
                                      && touched.alerts && touched.alerts[i] && touched.alerts[i].message &&
                                      <FormLabel
                                        error
                                      >
                                        <Translate id={`resources.dialog.setup_alerts.errors.message.${errors.alerts[i].message}`} />
                                      </FormLabel>}
                                 </ItemGrid>
                                </Grid>
                               </ExpansionPanelDetails>
                             </ExpansionPanel>
                            </div>)
                          )) : (
                            <div>
                              <Button
                                color="info"
                                style={{width: "100%"}}
                                onClick={() => arrayHelpers.push(emptyAlertConfig)}
                              >
                                <Translate id="resources.dialog.setup_alerts.addAlert" />
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </FormControl>
                  </ItemGrid>
                <DialogActions>
                  <Button
                    onClick={this.props.handleClose}
                  >
                    <Translate id="resources.dialog.cancel" />
                  </Button>
                  {this.props.isUpdatingResource
                    ? <div><CircularProgress /></div>
                    :  <Button
                          color="success"
                          type="submit"
                          disabled={!isValid}
                        >
                          <Translate id="resources.dialog.save" />
                      </Button>
                    }
                </DialogActions>
                </Grid>
                <FormLabel
                  error
                >
                  {this.props.updateResourceError}
                </FormLabel>
              </Form>
        </CustomDialog>
    );
  }
}

export default withStyles(customInputStyle)(SetupAlertsDialog);
