import React, { Component }  from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid } from "@material-ui/core";
import { Translate, getTranslate } from "react-localize-redux";

import modalConfirm from '../../components/Dialog/confirm';

import {
  RegularCard,
  Button,
  ItemGrid,
  CustomPaginationTable,
} from "../../components";

import CustomerDialog from '../Forms/CustomerDialog.jsx'

import {
  createCustomer,
  deleteCustomer,
  getCustomer,
  updateCustomer,
} from "../../actions/admin";

const customersTableParameters = [
  {
    roles: [3],
    columnData: [
      { id: 'name', numeric: false, disablePadding: true, label: 'name' },
      { id: 'customer', numeric: false, disablePadding: true, label: 'customer' },
      { id: 'description', numeric: false, disablePadding: true, label: 'description' },
      { id: 'created', numeric: false, disablePadding: true, label: 'created' },
    ]
  },
];

class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      editDialogOpen: false,
      customer: {},
    };
  }

  _handleChange = (e) => {
    this.props.setFieldValue(e.target.name, e.target.value);
  }

  _handleClose = () => {
    this.setState({
      dialogOpen: false,
      editDialogOpen: false,
    });
  }

  _handleOpen = () => {
    this.setState({
      dialogOpen: true
    });
  }

  _handleEdit = userId => {
    this.props.getCustomer(userId, customer => {
      this.setState({
        editDialogOpen : true,
        customer
      })
    });
  }

  _deleteCustomer = (customerId, { title, message, confirmButtonText } ) => {
    modalConfirm(message,
     {title, confirmButtonText, noCancelButton: true})
      .then(result => {
        this.props.deleteCustomer(customerId);
      },
        cancel => {});
  }

  render() {
    const { translate } = this.props;
    const tableParams = customersTableParameters.find(parameters => parameters.roles.find(role => role === this.props.role));
    return (
      <div>
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={translate("customers.title")}
              headerColor="blue"
              cardSubtitle={
                  <Button
                    style={{margin: "0"}}
                    color="success"
                    onClick={this._handleOpen}
                  >
                    <Translate id="customers.add" />
                  </Button>
              }
              content={
                <CustomPaginationTable
                  data={this.props.customers}
                  deleteItem={id => this._deleteCustomer(id, {
                    message: translate("customers.modal.delete.message",
                      {
                        name: this.props.customers.find(customer => customer.id === id).name
                      }),
                    title: translate("customers.modal.delete.title"),
                    confirmButtonText: translate("customers.modal.delete.title")
                  })}
                  editItem={this._handleEdit}
                  columnData={tableParams.columnData}
                  editButton
                  deleteButton
                />
              }
            />
          </ItemGrid>
        </Grid>
        {this.state.dialogOpen
          && <CustomerDialog
              customer={{
                  customer: '',
                  name: '',
                  description: ''
              }}
              new
              title={translate("customers.dialog.create_title")}
              isOpen={this.state.dialogOpen}
              handleClose={this._handleClose}
              resources={this.props.resources}
              createCustomer={this.props.createCustomer}
              createCustomerError={this.props.createCustomerError}
              isCreatingCustomer={this.props.isCreatingCustomer}
            />}
        {this.state.editDialogOpen
          && <CustomerDialog
              edit
              title={translate("customers.dialog.edit_title")}
              enableReinitialize={true}
              customer={this.state.customer}
              isOpen={this.state.editDialogOpen}
              handleClose={this._handleClose}
              updateCustomer={this.props.updateCustomer}
              isUpdatingCustomer={this.props.isUpdatingCustomer}
            />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize),
  isCreatingCustomer: state.admin.isCreatingCustomer,
  isUpdatingCustomer: state.admin.isUpdatingCustomer,
  createCustomerError: state.admin.createCustomerError,
  customers: state.admin.customers,
  role: state.session.role,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  createCustomer,
  deleteCustomer,
  getCustomer,
  updateCustomer,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
