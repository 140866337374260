import { combineReducers } from 'redux';
import { localizeReducer } from "react-localize-redux";

import sessionReducer from './sessionReducer';
import adminReducer from './adminReducer';
import userReducer from './userReducer';
import appStateReducer from './appStateReducer';

const appReducer = combineReducers({
	localize: localizeReducer,
	session: sessionReducer,
	admin: adminReducer,
	user: userReducer,
	appState: appStateReducer,
});

const reducer = (state, action) => {
	if (action.type === 'SIGNOUT_REQUEST'
			|| action.type === 'TOKEN_EXPIRED'
			|| action.type === 'TOKEN_REMOVED'
			|| action.type === 'TOKEN_INVALID') {
		const localize = {...state.localize}; // copy localize object
    state = undefined
		return({...appReducer(state, action), localize}); // restore localize object
  }
  return appReducer(state, action);
}

export default reducer;
