const initialState = {
  resources: [],
  isUpdatingResource: false,
  alarms: [],
  profile: {},
  updateProfileError: '',
  snackbars: [],
  sendEmailErrMessage: '',
  isSendingEmail: false,
  setPasswordErrMessage: '',
  isSettingPassword: false,
  isCommandSending: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_RESOURCES_SUCCESS':
        return {
            ...state,
            resources: action.res.items,
        };

      case 'UPDATE_RESOURCE_REQUEST':
        return {
          ...state,
          isUpdatingResource: true,
        };

      case 'UPDATE_RESOURCE_SUCCESS':
        return {
          ...state,
          resources: state.resources.map(resource => resource.id === action.res.id ? action.res : resource),
          isUpdatingResource: false,
        };

      case 'UPDATE_RESOURCE_FAILURE':
        return {
          ...state,
          isUpdatingResource: false,
        };

      case 'GET_ALARMS_SUCCESS':
        return {
            ...state,
            alarms: action.res.items,
        };

      case 'GET_PROFILE_SUCCESS':
        return {
          ...state,
          profile: {...action.res},
        };

      case 'UPDATE_PROFILE_REQUEST':
        return {
          ...state,
          isUpdatingProfile: true,
        };

      case 'UPDATE_PROFILE_SUCCESS':
        return {
          ...state,
          profile: {...action.res},
          isUpdatingProfile: false,
        };

      case 'UPDATE_PROFILE_FAILURE':
        return {
          ...state,
          isUpdatingProfile: false,
          updateProfileError: action.err ? action.err.statusText : 'Unknown error',
        };

      case 'CREATE_SNACKBAR_REQUEST':
        return {
          ...state,
          snackbars: [...state.snackbars, {...action.data, id: action.id, timerId: action.timerId}]
        };

      case 'CLOSE_SNACKBAR_REQUEST':
        return {
          ...state,
          snackbars: state.snackbars.filter(snackbar => snackbar.id !== action.id )
        };

      case 'SEND_RESET_PASSWORD_EMAIL_REQUEST':
        return {
          ...state,
          isSendingEmail: true,
          sendEmailErrMessage: '',
        };

      case 'SEND_RESET_PASSWORD_EMAIL_SUCCESS':
        return {
          ...state,
          isSendingEmail: false,
        };

      case 'SEND_RESET_PASSWORD_EMAIL_FAILURE':
        return {
          ...state,
          isSendingEmail: false,
          sendEmailErrMessage: action.err ? action.err.statusText : 'Unknown error',
        };

      case 'SET_NEW_PASSWORD_REQUEST':
        return {
          ...state,
          isSettingPassword: true,
          setPasswordErrMessage: '',
        };

      case 'SET_NEW_PASSWORD_SUCCESS':
        return {
          ...state,
          isSettingPassword: false,
        };

      case 'SET_NEW_PASSWORD_FAILURE':
        return {
          ...state,
          isSettingPassword: false,
          setPasswordErrMessage: action.err
            ? (action.err.data ? action.err.data.message : action.err.statusText)
            : 'Unknown error',
        };

      case 'DELETE_ALARM_SUCCESS':
        return {
          ...state,
          alarms: state.alarms.filter(alarm => alarm.id !== action.id),
        };

      case 'UPDATE_ALARM_SUCCESS':
        return {
          ...state,
          alarms: state.alarms.map(alarm => alarm.id === action.res.id
                ? action.res
                : alarm),
        };
      
      case 'SEND_COMMAND_REQUEST':
        return {
            ...state,
            isCommandSending: true,
        }
      
      case 'SEND_COMMAND_SUCCESS':
        return {
            ...state,
            resources: state.resources.map(resource => resource.id === action.res.id ? action.res : resource),
            isCommandSending: false,
        }      
      case 'SEND_COMMAND_FAILURE':
        return {
            ...state,
            isCommandSending: false,
        }
      
      default:
        return {...state};
    }
}

export default userReducer;
