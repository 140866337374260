import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import {
  Button,
} from "../../components";
import { backupDB, restoreDB } from '../../actions/admin';

class Settings extends Component {
  handleChange = (e) => {
    if(e.target.files[0]) {
      this.props.restoreDB(e.target.files[0]);
    }
  }

  render() {
    const { backupInProgress, backupDB, restoreInProgress } = this.props;
    return (
      <div>
        <h6><Translate id="settings.title" /></h6>
        <div><em>Disabled after migration to mysql db</em></div>
        <Button
          onClick={backupDB}
          disabled={true || backupInProgress || restoreInProgress}
          color='info'
        >
          <Translate id="settings.backup" />
        </Button>
        <input
          style={{display: 'none'}}
          accept=".tar"
          id="button-file"
          type="file"
          value=""
          onChange={this.handleChange}
        />
        <label htmlFor="button-file">
          <Button
            component="span"
            color='blue'
            disabled={true || backupInProgress || restoreInProgress}
          >
            <Translate id="settings.restore" />
          </Button>
        </label>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  backupInProgress: state.admin.backupInProgress,
  restoreInProgress: state.admin.restoreInProgress
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  backupDB,
  restoreDB
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
