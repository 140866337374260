import React, { Component }  from "react";
import { Formik, Field, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { Grid, CircularProgress, FormControl, FormLabel, DialogActions } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import moment from 'moment';

import {
  Button,
  CustomInput,
  ItemGrid,
  CustomDialog,
} from "../../components";

const ResourceFormVS = yupObject().shape({
  commandInHex: yupString()
  .matches(/^[a-fA-F0-9]+$/i)
  .required()
});

const SendCommandDialog = ({
  resource,
  sendCommand,
  enableReinitialize,
  handleClose,
  ...props
}) =>
  <Formik
  	initialValues={{ commandInHex: resource.downlinkData || '' }}
  	validationSchema={ResourceFormVS}
    isInitialValid={props => {
      ResourceFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={async (values, { props }) => {
      try {
        await sendCommand(resource.id, values.commandInHex, () => handleClose());
      } catch (e) {
        throw e;
      }
    }}
    render={renderProps => <SendCommandForm {...renderProps} {...props}
      handleClose={handleClose}
      resource={resource}
    />}
  />

class SendCommandForm extends Component {
  render() {
    const {
      resource,
      errors,
      touched,
      isValid,
    } = this.props;
    return (
        <CustomDialog
          title={this.props.title}
          handleClose={this.props.handleClose}
          open={this.props.isOpen}
          maxWidth='sm'
        >
            <Form className="form">
              <Grid container justify="flex-end">
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="commandInHex"
                    placeholder="Command in HEX"
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText="Command in HEX"
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isCommandSending
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors["commandInHex"] && touched["commandInHex"] &&
                    <FormLabel
                      error
                    >
                      {errors["commandInHex"]}
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                {resource.downLinkDataSent
                ? <FormLabel>Last command sent: {moment(resource.downLinkDataSent).format()}</FormLabel>
                : null}
                </ItemGrid>
                <DialogActions>
                  <Button
                    onClick={this.props.handleClose}
                  >
                    <Translate id="resources.dialog.cancel" />
                  </Button>
                  {this.props.isCommandSending
                    ? <div><CircularProgress /></div>
                    :  <Button
                          color="success"
                          type="submit"
                          disabled={!isValid}
                        >
                          <Translate id="resources.dialog.send_command.save" />
                      </Button>
                    }
                </DialogActions>
                </Grid>
                <FormLabel
                  error
                >
                  {this.props.error}
                </FormLabel>
              </Form>
        </CustomDialog>
    );
  }
}

export default SendCommandDialog;
