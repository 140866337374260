import React, { Component }  from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, TableBody, TableRow, TableCell, Table } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { getTranslate } from "react-localize-redux";

import ResourceDialog from '../Forms/ResourceDialog.jsx'
import SetupAlertsDialog from '../Forms/SetupAlertsDialog.jsx'
import ResourceMessagesDialog from '../Forms/ResourceMessagesDialog.jsx'
import SendCommandDialog from '../Forms/SendCommandDialog.jsx'

import { FilterInput } from '../../components';

import { isResourceAssigned } from '../../utils';
import {
  RegularCard,
  ItemGrid,
  CustomPaginationTable,
} from "../../components";

import {
  updateResource,
  updateEmailAlerts,
  getMessages,
  sendCommand,
} from "../../actions/user";

const resourcesTableParameters = [
  {
    roles: [0, 1, 2],
    columnData: [
      { id: 'id', numeric: false, disablePadding: true, label: 'id' },
      { id: 'name', numeric: false, disablePadding: true, label: 'name' },
      { id: 'downlinkData', numeric: false, disablePadding: true, label: '', hidden: true },
      { id: 'downLinkDataSent', numeric: false, disablePadding: true, label: '', hidden: true },
    ]
  },
  {
    roles: [3],
    columnData: [
      { id: 'id', numeric: false, disablePadding: true, label: 'id' },
      { id: 'name', numeric: false, disablePadding: true, label: 'name' },
      { id: 'customer', numeric: false, disablePadding: true, label: 'customer' },
      { id: 'downlinkData', numeric: false, disablePadding: true, label: '', hidden: true },
      { id: 'downLinkDataSent', numeric: false, disablePadding: true, label: '', hidden: true },
    ]
  },
];

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editDialogOpen: false,
      aetupAlertsDialogOpen: false,
      viewMessagesDialogOpen: false,
      sendCommandDialogOpen: false,
      selectedResource: null,
      resource: {},
      messages: [],
      filter: (props.resources && props.match.params.id
        ? props.resources.reduce((acc, resource) =>
          resource.viewFields.reduce((result, key) => result.find(field => key === field) ? result : [...result, key], acc)
          , props.role === 3 ? ['id', 'name', 'customer'] : ['id', 'name'])
        : props.role === 3 ? ['id', 'name', 'customer'] : ['id', 'name'])
        .reduce((acc, field) =>
          ({...acc, [field]: ''})
        , {})
    };
  }

  _handleChange = (e) => {
    this.props.setFieldValue(e.target.name, e.target.value);
  }

  _handleClose = () => {
    this.setState({
      editDialogOpen: false,
      viewMessagesDialogOpen: false,
      setupAlertsDialogOpen: false,
      sendCommandDialogOpen: false,
      selectedResource: null,
    });
  }

  _handleSetFilter = (field, value) => {
    this.setState({
      filter: {...this.state.filter, [field]: value}
    });
  }

  _handleEdit = resourceId => {
      const resource = this.props.resources.find(resource => resource.id === resourceId);
      this.setState({
        editDialogOpen : true,
        resource: resource
      })
  }

  _setupAlerts = resourceId => {
    const resource = this.props.resources.find(resource => resource.id === resourceId);
    const ALERT_FIELD_PATTERN = /^alert_email_\d{1,}$/;

    this.setState({
      setupAlertsDialogOpen : true,
      resource: Object.keys(resource).reduce((acc, k) =>
            k.match(ALERT_FIELD_PATTERN)
            ? {...acc, [k]: (resource[k][1] !== false && resource[k][1] !== true) ? [resource[k][0], false, ...resource[k].slice(1)] : resource[k]}
            : acc
          , resource)
    })
  }

  _viewMessages = (resourceId) => {
    this.props.getMessages(resourceId, messages => {
      const file = new Blob([JSON.stringify(messages, null, 2)], {type: 'text/plain'});
      const messages_json = URL.createObjectURL(file);
      this.setState({
        viewMessagesDialogOpen : true,
        selectedResource: resourceId,
        messages,
        messages_json
      })
    });
  }

  _sendCommand = (resourceId) => {
    const resource = this.props.resources.find(resource => resource.id === resourceId);
    const { id, downlinkData, downLinkDataSent } = resource;
    this.setState({
      sendCommandDialogOpen : true,
      selectedResource: { id, downlinkData, downLinkDataSent },
    })
  }
  
  render() {
    const { translate } = this.props;
    const { id } = this.props.match.params;
    const viewFields = this.props.resources && id
      ? this.props.resources.reduce((acc, resource) =>
        resource.viewFields.reduce((result, key) => result.find(field => key === field) ? result : [...result, key], acc)
        , this.props.role === 3 ? ['id', 'name', 'customer'] : ['id', 'name'])
      : [];

    const tableParams = resourcesTableParameters.find(parameters => parameters.roles.find(role => role === this.props.role) || parameters.roles.find(role => role === this.props.role) === 0);

    const columnData = id
      ? viewFields.map(field => ({
          id: field, numeric: false, disablePadding: true, label: field
      }))
      : tableParams.columnData;

    return (
      <div>
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
              <RegularCard
                cardTitle={
                  id
                  ? translate("resources.title1", {name: this.props.resources[0].resourceType.name})
                  : translate("resources.title2")
                }
                headerColor="green"
                content={<div>
                  <Table style={{tableLayout: "fixed"}}>
                    <TableBody>
                      <TableRow>
                        {columnData
                          .filter(column => !column.hidden)
                          .map(column =>
                          <TableCell
                            style={{width: column.width ? column.width : "100%"}}
                            key={column.id}
                          >
                            <FilterInput
                              field={column.id}
                              value={this.state.filter[column.id]}
                              handleChange={this._handleSetFilter}
                            />
                          </TableCell>
                        )}
                        <TableCell style={{width: "60%"}}>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <CustomPaginationTable
                    orderBy="id"
                    data={this.props.resources.filter(resource =>
                        columnData.map(field =>
                          !this.state.filter[field.id] ||
                          (resource.hasOwnProperty(field.id) &&
                          this.state.filter[field.id] &&
                          JSON.stringify(resource[field.id]).toUpperCase().includes(this.state.filter[field.id].toUpperCase()))
                        ).every(el => el)
                      ).map(resource => resource.UrlAPI ? {...resource,
                      UrlAPI: <Done style={{color: "green"}} />} : {...resource})}
                    columnData={columnData}
                    editItem={this._handleEdit}
                    viewMessages={this._viewMessages}
                    messagesButton
                    sendCommandButton={this.props.role}
                    sendCommand={this._sendCommand}
                    setupAlerts={this._setupAlerts}
                    editButton={this.props.role}
                  />
                </div>}
              />
          </ItemGrid>
        </Grid>
        {this.state.editDialogOpen
          && <ResourceDialog
              title={translate("resources.dialog.edit_title")}
              enableReinitialize={true}
              role={this.props.role}
              resource={this.state.resource}
              isOpen={this.state.editDialogOpen}
              handleClose={this._handleClose}
              updateResource={this.props.updateResource}
              isUpdatingResource={this.props.isUpdatingResource}
            />}
        {this.state.setupAlertsDialogOpen
          && <SetupAlertsDialog
              title={translate("resources.dialog.setup_alerts.title")}
              enableReinitialize={true}
              resource={this.state.resource}
              isOpen={this.state.setupAlertsDialogOpen}
              handleClose={this._handleClose}
              updateResource={this.props.updateEmailAlerts}
              isUpdatingResource={this.props.isUpdatingResource}
            />}
        {this.state.viewMessagesDialogOpen
          && <ResourceMessagesDialog
              messages={this.state.messages}
              resource={this.state.selectedResource}
              messages_json={this.state.messages_json}
              isOpen={this.state.viewMessagesDialogOpen}
              handleClose={this._handleClose}
            />}
        {this.state.sendCommandDialogOpen
          && <SendCommandDialog
              resource={this.state.selectedResource}
              isOpen={this.state.sendCommandDialogOpen}
              handleClose={this._handleClose}
              sendCommand={this.props.sendCommand}
              isCommandSending={this.props.isCommandSending}
              title={translate("resources.dialog.send_command.title")}
            />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize),
  resources: ownProps.match.params.id
    ? state.user.resources.filter(resource => isResourceAssigned(resource) && resource.resourceTypeId === ownProps.match.params.id)
    : state.user.resources.filter(resource => !isResourceAssigned(resource)),
  isUpdatingResource: state.user.isUpdatingResource,
  role: state.session.role,
  isCommandSending: state.user.isCommandSending,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  updateResource,
  updateEmailAlerts,
  getMessages,
  sendCommand,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
