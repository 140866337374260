import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Button,
} from "./components";

import cfg from './config';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

Sentry.init({ dsn: isLocalhost ? null : cfg.SENTRY_DSN });

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { eventId: null };
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      Sentry.withScope(scope => {
          scope.setExtras(errorInfo);
          const eventId = Sentry.captureException(error);
          this.setState({eventId: eventId});
      });
    }

    render() {
        if (this.state.hasError) {
            //render fallback UI
            return (
              <div style={{ margin: '2rem'}}>
                <div>
                  <h4>Error occured</h4>
                  <div style={{ textAlign: 'center' }}>
                    <Button color="info" onClick={() => window.location.reload()}>
                      Reload page
                    </Button>
                    <br />or<br />
                    <Button color="white" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>
                  </div>
                </div>
              </div>
            );
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default ErrorBoundary;
