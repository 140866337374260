export const toSearchParams = (data) => {
  const searchParams = new URLSearchParams();
  Object.keys(data).forEach(key => searchParams.append(key, data[key]));
  return searchParams;
};

export const toFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => { if(data[key] !== null && data[key] !== '') formData.append(key, data[key]); });
  return formData;
};

export const isJSON = testString => {
    try {
        var o = JSON.parse(testString);
        if (o && (typeof o === "object" || typeof o === "number")) {
            return o;
        }
    }
    catch (e) { }
    return false;
};

export const isResourceAssigned = resource =>
  resource.hasOwnProperty('resourceTypeId')
  && resource.hasOwnProperty('viewFields')
  && resource.hasOwnProperty('editFields');
  
