import React, { Component }  from "react";
import { Formik, Field, Form } from 'formik';
import { object as yupObject } from 'yup';
import { Grid, CircularProgress, FormControl, FormLabel, DialogActions } from "@material-ui/core";
import { Translate } from "react-localize-redux";

import { isJSON } from '../../utils';

import {
  Button,
  CustomInput,
  ItemGrid,
  CustomDialog,
} from "../../components";

const ResourceFormVS = yupObject().shape({
});

const ResourceDialog = ({
  resource,
  updateResource,
  enableReinitialize,
  handleClose,
  ...props
}) =>
  <Formik
  	initialValues={resource.hasOwnProperty('editFields')
    ? {...resource.editFields.reduce((acc, field) => ({...acc, [field]: ''}), {}), ...resource}
    : resource}
  	validationSchema={ResourceFormVS}
    isInitialValid={props => {
      ResourceFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={(values, { props }) => {
        const data = values.hasOwnProperty('editFields')
        ? values.editFields.reduce((acc, field) =>
          ({...acc, [field]: isJSON(values[field]) || values[field]})
        , {})
        : {};
    		updateResource(resource.id, data, () => handleClose());
    	}
    }
    render={renderProps => <ResourceForm {...renderProps} {...props}
      handleClose={handleClose}
    />}
  />

class ResourceForm extends Component {
  render() {
    const {
      role,
      errors,
      touched,
      values,
      isValid,
    } = this.props;
    return (
        <CustomDialog
          title={this.props.title}
          handleClose={this.props.handleClose}
          open={this.props.isOpen}
          maxWidth='sm'
        >
            <Form className="form">
              <Grid container justify="flex-end">
                {Object.keys(values)
                  .filter(value => !Boolean(role < 3 && (![...values.viewFields, ...values.editFields].find(field => field === value))))
                  .map((value, k) =>
                  <ItemGrid xs={12} sm={12} md={12} key={k}>
                  <FormControl fullWidth>
                    <Field
                      name={value}
                      placeholder={value}
                      type="text"
                      render={({field, form}) => (
                        <CustomInput
                         labelText={value}
                         inputProps={{
                           "name": field.name,
                           "value": typeof field.value === 'object' ? JSON.stringify(field.value) : field.value,
                           "onChange": form.handleChange,
                           "onBlur": form.handleBlur,
                           "disabled": this.props.isCreatingCustomer
                         }}
                         formControlProps={{
                           "disabled": !Boolean(values.hasOwnProperty('editFields') && values.editFields.find(editField => editField === value))
                         }}
                       />
                      )}
                    />
                    {errors[value] && touched[value] &&
                      <FormLabel
                        error
                      >
                        {errors[value]}
                      </FormLabel>}
                  </FormControl>
                  </ItemGrid>
                )}
                <DialogActions>
                  <Button
                    onClick={this.props.handleClose}
                  >
                    <Translate id="resources.dialog.cancel" />
                  </Button>
                  {this.props.isUpdatingResource
                    ? <div><CircularProgress /></div>
                    :  <Button
                          color="success"
                          type="submit"
                          disabled={!isValid}
                        >
                          <Translate id="resources.dialog.save" />
                      </Button>
                    }
                </DialogActions>
                </Grid>
                <FormLabel
                  error
                >
                  {this.props.updateResourceError}
                </FormLabel>
              </Form>
        </CustomDialog>
    );
  }
}

export default ResourceDialog;
