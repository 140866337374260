import React, { Component }  from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid } from "@material-ui/core";
import { Translate, getTranslate } from "react-localize-redux";

import {
  RegularCard,
  Button,
  ItemGrid,
  CustomPaginationTable,
} from "../../components";

import ResourceTypeDialog from '../Forms/ResourceTypeDialog.jsx'

import {
  getResources,
} from "../../actions/user";

import {
  getResourceType,
  createResourceType,
  updateResourceType,
} from "../../actions/admin";

const resourceTypesTableParameters = [
  {
    roles: [2, 3],
    columnData: [
      { id: 'name', numeric: false, disablePadding: true, label: 'name' },
      { id: 'ResourceTypeCustomer', numeric: false, disablePadding: true, label: 'ResourceTypeCustomer' },
      { id: 'menuText', numeric: false, disablePadding: true, label: 'menuText' },
      { id: 'description', numeric: false, disablePadding: true, label: 'description' },
    ]
  },
];


class ResourceTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      editDialogOpen: false,
      resourceType: {},
    };
  }

  _handleClose = (e, _, refetchResources) => {
    this.setState({
      dialogOpen: false,
      editDialogOpen: false,
    }, refetchResources ? () => this.props.getResources() : () => {});
  }

  _handleOpen = () => {
    this.setState({
      dialogOpen: true
    });
  }

  _handleEdit = resourceTypeId => {
    this.props.getResourceType(resourceTypeId, resourceType => {
      this.setState({
        editDialogOpen : true,
        resourceType
      })
    });
  }

  render() {
    const { translate } = this.props;
    const tableParams = resourceTypesTableParameters.find(parameters => parameters.roles.find(role => role === this.props.role));
    return (
      <div>
        <Grid container>
            <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={translate("resource_types.title")}
              headerColor="purple"
              cardSubtitle={
                this.props.role === 3
                &&
                <Button
                  style={{margin: "0"}}
                  color="success"
                  onClick={this._handleOpen}
                >
                  <Translate id="resource_types.add" />
                </Button>
              }
              content={
                <CustomPaginationTable
                  data={this.props.resourceTypes}
                  columnData={tableParams.columnData}
                  editItem={this._handleEdit}
                  editButton
                />
              }
            />
          </ItemGrid>
        </Grid>
        {this.state.dialogOpen
          && <ResourceTypeDialog
              resourceType={{
                  name: '',
                  description: '',
                  menuText: '',
                  ResourceTypeCustomer: '',
                  viewFields: [],
                  editFields: [],
              }}
              title={translate("resource_types.dialog.create_title")}
              role={this.props.role}
              isOpen={this.state.dialogOpen}
              customers={this.props.customers}
              handleClose={this._handleClose}
              submitResourceType={this.props.createResourceType}
              isProcessingResourceType={this.props.isCreatingResourceType}
              resourceTypeError={this.props.createResourceTypeError}
            />}
        {this.state.editDialogOpen
          && <ResourceTypeDialog
              title={translate("resource_types.dialog.edit_title")}
              role={this.props.role}
              resourceType={{
                ...this.state.resourceType,
                description: this.state.resourceType.description || '',
                ResourceTypeCustomer: this.state.resourceType.ResourceTypeCustomer || '',
                menuText: this.state.resourceType.menuText || '',
                viewFields: this.state.resourceType.viewFields || [],
                editFields: this.state.resourceType.editFields || [],
              }}
              isOpen={this.state.editDialogOpen}
              customers={this.props.customers}
              handleClose={this._handleClose}
              submitResourceType={this.props.updateResourceType}
              isProcessingResourceType={this.props.isUpdatingResourceType}
              resourceTypeError={this.props.updateResourceTypeError}
              showWarning
            />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize),
  resourceTypes: state.admin.resourceTypes,
  role: state.session.role,
  customers: state.admin.customers,
  isCreatingResourceType: state.admin.isCreatingResourceType,
  createResourceTypeError: state.admin.createResourceTypeError,
  isUpdatingResourceType: state.admin.isUpdatingResourceType,
  updateResourceTypeError: state.admin.updateResourceTypeError,
});

const mapDispatchToProps = dispatch => (bindActionCreators({
  getResourceType,
  createResourceType,
  updateResourceType,
  getResources,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(ResourceTypes);
