import React, { Fragment } from "react";
import ReactJson from 'react-json-view';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Translate } from "react-localize-redux";

import {
  CustomDialog,
} from "../../components";

import {
  IconButton,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";

const styles = theme => ({
  paperWidth: {
    minWidth: "600px",
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
});

const ResourceMessagesDialog = ({
  resource,
  handleClose,
  isOpen,
  messages,
  classes,
  messages_json
}) => (
        <CustomDialog
          title={
            <Fragment>
              <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                <Close />
              </IconButton>
              <Translate id="resources.dialog.messages.title" data={{resource, length: messages.length}} />
            </Fragment>
          }
          handleClose={handleClose}
          open={isOpen}
          maxWidth='md'
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          classes={{
            paper: classes.paperWidth
          }}
        >
          {messages.length === 0
            ? <span><Translate id="resources.dialog.messages.no_messages" /></span>
            : messages.map((message, i) =>
              <ReactJson
                  key={i}
                  name={(new Date(message.timestamp)).toISOString()}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  src={message}
                  enableClipboard={({ src }) => navigator.clipboard
                    && navigator.clipboard.writeText
                    && navigator.clipboard.writeText(JSON.stringify(src, null, 2))}
                />
            )
          }
          {messages.length ? <a href={messages_json} download={`${resource}_${moment().format('YYYYMMDHHmmss')}_msg.json`} style={{alignSelf: 'flex-end'}}><Translate id="resources.dialog.messages.download" /></a> : null}
        </CustomDialog>
    );

export default withStyles(styles)(ResourceMessagesDialog);
