import React, { Component }  from "react";
import { Formik, Field, Form, FieldArray } from 'formik';
import { ref as yupRef, object as yupObject, array as yupArray, string as yupString, boolean as yupBoolean, number as yupNumber } from 'yup';
import { Grid, CircularProgress, FormControl, FormLabel, DialogActions, Select, MenuItem, Button as CoreButton } from "@material-ui/core";
import { Translate } from "react-localize-redux";

import {
  Button,
  CustomInput,
  ItemGrid,
  CustomDialog,
} from "../../components";

const UserFormVS = yupObject().shape({
  sendEmail: yupBoolean(),
  loginEmail: yupString()
    .email('invalid')
    .required('required'),
  password: yupString()
    .when('sendEmail', {
      is: false,
      then: yupString()
        .required('required'),
      otherwise: yupString()
    }),
  confirmPassword: yupString()
    .when('sendEmail', {
      is: false,
      then: yupString()
        .oneOf([yupRef('password', null)], 'match')
        .required('required'),
      otherwise: yupString()
    }),
    role: yupNumber(),
    idCompanies: yupArray().of(yupString())
      .when('role', {
        is: role => role === 3,
        then: yupArray().of(yupString())
          .max(0),
        otherwise: yupArray().of(yupString().min(1))
          .min(1)
      })
});

const UserDialog = ({
  user,
  isOpen,
  resources,
  createUser,
  updateUser,
  isCreatingUser,
  handleClose,
  title,
  enableReinitialize,
  isUpdatingUser,
  role,
  customers,
  createUserError,
  sendEmail,
  createUserWithoutConfirmation,
}) =>
  <Formik
  	initialValues={{...user, sendEmail}}
  	validationSchema={UserFormVS}
    isInitialValid={props => {
      UserFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={(values, { props }) => {
    		const data = {
          name: values.name,
          description: values.description,
          idCompanies: values.idCompanies,
          role: values.role,
    			loginEmail: values.loginEmail,
    		};
    		values.sendEmail
        ? createUser(data, () => handleClose())
        : createUserWithoutConfirmation({...data, password: values.password}, () => handleClose());
    	}
    }
    render={props => <UsersForm {...props}
      user={user}
      isOpen={isOpen}
      resources={resources}
      handleClose={handleClose}
      isCreatingUser={isCreatingUser}
      title={title}
      isUpdatingUser={isUpdatingUser}
      role={role}
      customers={customers}
      createUserError={createUserError}
    />}
  />

class UsersForm extends Component {
  _handleChange = (e) => {
    this.props.setFieldValue(e.target.name, e.target.value);
  }

  _handleSelect = (e) => {
    this.props.setFieldValue(e.target.name, e.target.value);
    if(e.target.value === 3) {
      this.props.setFieldValue('idCompanies', []);
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      isValid,
      setFieldValue,
    } = this.props;
    return (
        <CustomDialog
          title={this.props.title}
          handleClose={this.props.handleClose}
          open={this.props.isOpen}
        >
            <Form className="form">
              <Grid container justify="flex-end">
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="name"
                    placeholder={<Translate id="users.dialog.name" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="users.dialog.name" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isCreatingUser
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.name && touched.name &&
                    <FormLabel
                      error
                    >
                      <Translate id={`users.dialog.errors.name.${errors.name}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="loginEmail"
                    type="email"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="users.dialog.email" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isCreatingUser
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.loginEmail && touched.loginEmail &&
                    <FormLabel
                      error
                    >
                      <Translate id={`users.dialog.errors.email.${errors.loginEmail}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                {(this.props.role === 3) &&
                  <ItemGrid xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <Field
                      name="sendEmail"
                      render={({field, form}) => (
                        <label>
                          <input
                            type="checkbox"
                            checked={values.sendEmail}
                            onChange={e => setFieldValue("sendEmail", e.target.checked)}
                          />
                            {<Translate id="users.dialog.send_email" />}
                        </label>
                      )}
                    />
                  </FormControl>
                  </ItemGrid>}
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <Field
                    name="description"
                    placeholder={<Translate id="users.dialog.description" />}
                    type="text"
                    render={({field, form}) => (
                      <CustomInput
                       labelText={<Translate id="users.dialog.description" />}
                       inputProps={{
                         "name": field.name,
                         "value": field.value,
                         "onChange": form.handleChange,
                         "onBlur": form.handleBlur,
                         "disabled": this.props.isCreatingUser
                       }}
                       formControlProps={{
                       }}
                     />
                    )}
                  />
                  {errors.description && touched.description &&
                    <FormLabel
                      error
                    >
                      <Translate id={`users.dialog.errors.description.${errors.description}`} />
                    </FormLabel>}
                </FormControl>
                </ItemGrid>
                {((this.props.role === 3 || this.props.role === 2)
                && values.role !== 3) &&
                <ItemGrid xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <FieldArray
                    name="idCompanies"
                    render={arrayHelpers => (
                      <div>
                        {values.idCompanies && values.idCompanies.length > 0
                        ? (values.idCompanies.map((idCompany, i) =>
                          <div key={i} style={{display: "flex"}}>
                          <Field
                            name={`idCompanies.${i}`}
                            render={({field, form}) => (
                              <Select
                               style={{flex: "1 1 100%"}}
                               inputProps={{
                                 "name": field.name,
                                 "value": field.value,
                                 "onChange": form.handleChange,
                                 "disabled": this.props.isCreatingUser
                               }}
                              >
                               {this.props.customers
                                 .filter(customer => !values.idCompanies.find(idCompany => (idCompany === customer.id) && (idCompany !== field.value)))
                                 .map(customer =>
                                   <MenuItem value={customer.id} key={customer.id}>
                                     <em>{customer.name}</em>
                                   </MenuItem>
                               )}
                              </Select>
                            )}
                          />
                          <CoreButton
                            color="secondary"
                            style={{minWidth: "27px", width: "27px", fontWeight: "bold"}}
                            onClick={() => arrayHelpers.remove(i)}
                          >
                            -
                          </CoreButton>
                          <CoreButton
                            style={{minWidth: "27px", width: "27px", marginLeft: "2px", color: "green", fontWeight: "bold"}}
                            onClick={() => arrayHelpers.insert(i + 1, '')}
                          >
                            +
                          </CoreButton>
                          </div>
                        ))
                      : (<Button color="info" style={{width: "100%"}} onClick={() => arrayHelpers.push('')}>
                            {<Translate id="users.dialog.addCustomer" />}
                        </Button>)}
                      </div>
                    )}
                  />
                </FormControl>
                </ItemGrid>}
                <ItemGrid xs={12} sm={12} md={4} style={{display: "flex", alignItems: "center"}}>
                  <FormLabel><Translate id="users.dialog.role_label" /></FormLabel>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={8}>
                <FormControl fullWidth>
                  <Field
                    name="role"
                    value="role"
                    placeholder={<Translate id="users.dialog.role" />}
                    type="select"
                    render={({field, form}) => (
                      <Select
                       name={field.name}
                       value={field.value}
                       onChange={this._handleSelect}
                     >
                      <MenuItem value={0}>
                        <em><Translate id="users.roles.viewer" /></em>
                      </MenuItem>
                      <MenuItem value={1}>
                        <em><Translate id="users.roles.editor" /></em>
                      </MenuItem>
                      <MenuItem value={2}>
                        <em><Translate id="users.roles.admin" /></em>
                      </MenuItem>
                      {(this.props.role === 3) &&
                        <MenuItem value={3}>
                          <em><Translate id="users.roles.superadmin" /></em>
                        </MenuItem>}
                     </Select>
                    )}
                  />
                </FormControl>
                </ItemGrid>
                {values.sendEmail
                ? null
                : <ItemGrid xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <Field
                        name="password"
                        placeholder={<Translate id="users.dialog.password" />}
                        render={({field, form}) => (
                          <CustomInput
                           labelText={<Translate id="users.dialog.password" />}
                           inputProps={{
                             "type": "password",
                             "name": field.name,
                             "value": field.value,
                             "onChange": form.handleChange,
                             "onBlur": form.handleBlur,
                             "disabled": this.props.isCreatingUser
                           }}
                           formControlProps={{
                           }}
                         />
                        )}
                      />
                      {errors.password && touched.password &&
                        <FormLabel
                          error
                        >
                          <Translate id={`users.dialog.errors.password.${errors.password}`} />
                        </FormLabel>}
                    </FormControl>
                  </ItemGrid>}
                {values.sendEmail
                ? null
                : <ItemGrid xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <Field
                      name="confirmPassword"
                      placeholder={<Translate id="users.dialog.confirm_password" />}
                      render={({field, form}) => (
                        <CustomInput
                         labelText={<Translate id="users.dialog.confirm_password" />}
                         inputProps={{
                           "type": "password",
                           "name": field.name,
                           "value": field.value,
                           "onChange": form.handleChange,
                           "onBlur": form.handleBlur,
                           "disabled": this.props.isCreatingUser
                         }}
                         formControlProps={{
                         }}
                       />
                      )}
                    />
                    {errors.confirmPassword && touched.confirmPassword &&
                      <FormLabel
                        error
                      >
                        <Translate id={`users.dialog.errors.password.${errors.confirmPassword}`} />
                      </FormLabel>}
                  </FormControl>
                  </ItemGrid>}
                <DialogActions disableActionSpacing={false}>
                <Button
                  onClick={this.props.handleClose}
                >
                  <Translate id="users.dialog.cancel" />
                </Button>
                {(this.props.edit ? this.props.isUpdatingUser : this.props.isCreatingUser)
                  ? <div><CircularProgress /></div>
                  :  <Button
                        color="success"
                        type="submit"
                        disabled={!isValid}
                      >
                        <Translate id="users.dialog.save" />
                    </Button>
                  }
                </DialogActions>
                </Grid>
                <FormLabel
                  error
                >
                  {this.props.createUserError}
                </FormLabel>
              </Form>
        </CustomDialog>
    );
  }
}

export default UserDialog;
